.statsNguts__fantasyQuestionByCombinations {
  margin-bottom: 20px;
  width: 100%;
  font-family: var(--font-family);
}

.statsNguts__fantasyQuestionByCombinations_box {
  background: linear-gradient(to bottom, #dfe1e6, rgb(184, 197, 231));
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 24px;
  transition: all 0.3s ease;
}

.statsNguts__fantasyQuestionByCombinations_title {
  margin-bottom: 16px;
  color: #333;
  font-size: 24px;
}

.statsNguts__fantasyQuestionByCombinations_options_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  justify-content: center;
  margin-bottom: 16px;
  max-height: 200px;
  overflow-y: auto;
}

.statsNguts__fantasyQuestionByCombinations_options_tab {
  flex-grow: 1;
  text-align: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.statsNguts__fantasyQuestionByCombinations_options_tab:hover, .statsNguts__fantasyQuestionByCombinations_options_tab:focus {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.statsNguts__fantasyQuestionByCombinations_stats {
  font-size: 16px;
  color: #666;
}

.statsNguts__fantasyQuestionByCombinations_stats_content {
  background: #ddd;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
}

.statsNguts__fantasyQuestionByCombinations_stats_content h2 {
  width: 100%;
  background-color: #9ab6f3;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
}

.statsNguts__fantasyQuestionByCombinations_stats_content h3 {
  margin-top: 10px;
  font-size: 14px;
  color: #666666;
  margin-bottom: 5px;
}

.statsNguts__fantasyQuestionByCombinations_stats_content p {
  font-size: 14px;
  color: #022007;
  margin-bottom: 5px;
}

.statsNguts__fantasyQuestionByCombinations_options_tab.selected {
  background-color: #075213;
  color: #ffffff;
  font-weight: bold;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigationButtons button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;
}

.navigationButtons button:hover {
  background-color: #45a049;
}

.navigationButtons button:focus {
  outline: none;
}

.navigationButtons button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.navigationButtons button:disabled:hover {
  background-color: #cccccc;
}

.statsNguts__fantasyQuestionByCombinations_chartContainer {
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  flex-wrap: wrap; 
  padding: 10px; 
}

.statsNguts__fantasyQuestionByCombinations_chartContainer_chart {
  width: calc(100% - 20px); 
  margin-bottom: 20px; 
  margin-right: 10px;
  margin-left: 10px;
}

@media screen and (max-width: 1024px) {
  .statsNguts__fantasyQuestionByCombinations_options_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .statsNguts__fantasyQuestionByCombinations_title {
    font-size: 20px;
  }

  .navigationButtons button {
    font-size: 14px;
  }

  .statsNguts__fantasyQuestionByCombinations_chartContainer {
    flex-direction: column; 
    align-items: stretch; 
  }
  
  .statsNguts__fantasyQuestionByCombinations_chartContainer_chart {
    width: calc(100% - 20px); 
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__fantasyQuestionByCombinations_options_container {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .statsNguts__fantasyQuestionByCombinations_title {
    font-size: 20px;
  }

  .navigationButtons button {
    font-size: 14px;
  }

  .statsNguts__fantasyQuestionByCombinations_chartContainer {
    flex-direction: column; 
    align-items: stretch; 
  }
  
  .statsNguts__fantasyQuestionByCombinations_chartContainer_chart {
    width: 100%; 
    margin: 0 auto 20px; 
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__fantasyQuestionByCombinations_options_container {
    grid-template-columns: repeat(auto-fill, minmax(50, 1fr));
  }

  .statsNguts__fantasyQuestionByCombinations_options_tab {
    padding: 8px 16px;
    font-size: 12px;
  }

  .statsNguts__fantasyQuestionByCombinations_title {
    font-size: 14px;
  }

  .navigationButtons button {
    font-size: 12px;
  }

  .statsNguts__fantasyQuestionByCombinations_chartContainer {
    flex-direction: column; 
    align-items: stretch; 
  }
  
  .statsNguts__fantasyQuestionByCombinations_chartContainer_chart {
    width: 100%; 
    margin: 0 auto 20px; 
  }

  .statsNguts__fantasyQuestionByCombinations_stats_content h2 {
    font-size: 16px;
  }

  .statsNguts__fantasyQuestionByCombinations_stats_content h3 {
    font-size: 10px;
  }

  .statsNguts__fantasyQuestionByCombinations_stats_content p {
    font-size: 10px;
  }
}