.statsNguts__matchAnalysis {
  text-align: center;
  font-family: var(--font-family);
}

.statsNguts__matchAnalysis_summary {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center-align children horizontally */
  justify-content: center; /* Center content vertically, useful if the container has extra space */
}

.statsNguts__matchAnalysis_overallContainer {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.statsNguts__matchAnalysis_teamContainer {
  display: flex;
  flex-direction: column; 
  align-items: center;
}

.statsNguts__matchAnalysis_teamLogo {
  width: auto;
  height: auto;
}

.statsNguts__matchAnalysis_teamStatsContainer {
  margin-top: 20px;
}

.statsNguts__matchAnalysis_statCategory {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 300px; 
  margin-bottom: 10px; 
}

.statsNguts__matchAnalysis_categoryTitle {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #0c3d49;
  letter-spacing: 0.5px;
  padding: 8px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: block;
  width: auto;
  box-sizing: border-box; 
  margin-left: auto; 
  margin-right: auto; 
}

.statsNguts__matchAnalysis_categoryItem {
  font-size: 16px;
  font-weight: 500; 
  color: #8ad8a4;
  padding: 8px 0; 
  border-bottom: 1px solid #eaeaea; 
  margin: 8px 20px;
}

.statsNguts__matchAnalysis_categoryItem:hover {
  background-color: #242020;
}

.statsNguts__matchAnalysis_matchResult {
  font-size: 18px;
  margin: 20px auto;
  font-weight: bold;
  color: #FFFFFF;
  background: linear-gradient(145deg, #007BFF, #884DFF);
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: fit-content; 
  max-width: 100%; 
  box-sizing: border-box;
  display: inline-block; 
}

.statsNguts__matchAnalysis_motm {
  font-size: 18px;
  margin: 20px auto;
  font-weight: bold;
  color: #FFFFFF;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: fit-content; 
  max-width: 100%; 
  box-sizing: border-box;
  display: inline-block;
  background-color: #2c2e30;
}

@media (max-width: 1024px) {
  .statsNguts__matchAnalysis_overallContainer {
    display: flex;
    flex-direction: row; 
    justify-content: space-around; 
    align-items: flex-start;
    flex-wrap: nowrap; 
  }

  .statsNguts__matchAnalysis_teamContainer {
    flex: 0 1 50%; 
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px; 
    box-sizing: border-box;
  }

  .statsNguts__matchAnalysis_teamLogo {
    width: 100%;
    height: auto;
  }

  .statsNguts__matchAnalysis_teamStatsContainer,
  .statsNguts__matchAnalysis_statCategory {
    width: 100%;
  }

  .statsNguts__matchAnalysis_matchResult {
    width: 80%;
    max-width: 100%;
    margin: 10px auto;
    font-size: 14px;
  }

  .statsNguts__matchAnalysis_motm {
    width: 80%;
    max-width: 100%;
    margin: 10px auto;
    font-size: 14px;
  }

  .statsNguts__matchAnalysis_categoryTitle {
    font-size: 14px;
  }

  .statsNguts__matchAnalysis_categoryItem {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .statsNguts__matchAnalysis_overallContainer {
    flex-direction: column;
  }

  .statsNguts__matchAnalysis_teamContainer {
    flex: 0 1 100%; 
    width: 100%;
    padding: 0;
  }

  .statsNguts__matchAnalysis_logoContainer,
  .statsNguts__matchAnalysis_teamStatsContainer,
  .statsNguts__matchAnalysis_statCategory {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .statsNguts__matchAnalysis_teamLogo {
    max-width: 100%;
    height: auto;
  }

  .statsNguts__matchAnalysis_statCategory {
    box-sizing: border-box;
  }

  .statsNguts__matchAnalysis_matchResult {
    width: 100%;
    max-width: 100%;
    padding: 5px;
    margin: 20px 0;
    font-size: 10px;
  }

  .statsNguts__matchAnalysis_motm {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    margin: 5px 0;
    font-size: 10px;
  }

  .statsNguts__matchAnalysis_categoryTitle {
    font-size: 14px;
  }

  .statsNguts__matchAnalysis_categoryItem {
    font-size: 12px;
  }
}
