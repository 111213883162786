.statsNguts__player {
  font-family: var(--font-family);
  width: 100%;
}

.statsNguts__player_title.player-in-starting-eleven {
  padding: 5px;
  margin-top: 5px;
  text-align: center;
  background-color: #085f0b;
}

.statsNguts__player_title.player-in-impact-list {
  padding: 5px;
  margin-top: 5px;
  text-align: center;
  background-color: rgb(231, 231, 54);
}

.statsNguts__player_title.player-not-playing {
  padding: 5px;
  margin-top: 5px;
  text-align: center;
  background-color: #2b0303;
}

.statsNguts__player_title h3 {
  width: 100%;
  cursor: pointer;
  background-color: #9ab6f3;
  border: 2px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 20px;
  color: #333333;
}

.status-green {
  color: green;
}

.status-red {
  color: rgb(185, 25, 25);
}

.status-yellow {
  color: rgb(231, 231, 54);
}

.statsNguts__player_card {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: var(--font-family);
  width: 100%;
  height: auto;
  border: 2px solid #000;
}

.statsNguts__player_card_avatarContainer {
  flex: 0 0 20%;
}

.statsNguts__player_card_avatarContainer_avatar {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.statsNguts__player_card_name {
  background: #ddd;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
}

.statsNguts__player_card_name h4 {
  width: 100%;
  background-color: #9ab6f3;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
}

.statsNguts__player_card_country {
  background: #ddd;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
}

.statsNguts__player_card_country h4 {
  width: 100%;
  background-color: #9ab6f3;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
}

.statsNguts__player_card_price {
  background: #ddd;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
}

.statsNguts__player_card_price h4 {
  width: 100%;
  background-color: #9ab6f3;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
}

.statsNguts__player_card_batting_style {
  background: #ddd;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
}

.statsNguts__player_card_batting_style h4 {
  width: 100%;
  background-color: #9ab6f3;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
}

.statsNguts__player_card_bowling_style {
  background: #ddd;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
}

.statsNguts__player_card_bowling_style h4 {
  width: 100%;
  background-color: #9ab6f3;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
}

.statsNguts__player_card_player_availability {
  background: #dddddd;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
}

.statsNguts__player_card_player_availability.player-available h4 {
  width: 100%;
  background-color: #14c463;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
}

.statsNguts__player_card_player_availability.player-unavailable h4 {
  width: 100%;
  background-color: #e63f33;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
}

.statsNguts__player_card_player_overview {
  background: #245726;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
}

.statsNguts__player_card_player_overview h4 {
  width: 100%;
  background-color: #9ba7c2;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
  cursor: pointer;
}

.statsNguts__player_card_player_overview h5 {
  color: green;
  font-size: small;
}

.statsNguts__player_card_player_trends {
  background: #245726;
  padding: 5px;
  margin-top: 5px;
  text-align: center;
}

.statsNguts__player_card_player_trends h4 {
  width: 100%;
  background-color: #9ba7c2;
  border: 1px solid #ddd;
  margin: auto;
  padding: 10px;
  font-size: 18px;
  color: #333333;
  cursor: pointer;
}

.statsNguts__player_card_player_trends h5 {
  color: green;
  font-size: small;
}

.statsNguts__player_card_content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.statsNguts__player_card_playerOverview {
  height: auto;
}

.statsNguts__player_card_playerCharts {
  height: auto;
}

.statsNguts__player_card_stats_container {
    text-align: left;
    word-wrap: break-word;
    font-family: var(--font-family);
    margin: 10px;
    padding: 10px;
    flex-direction: column;
}

.statsNguts__player_card_stats_container h1 {
  font-size: 20px;
  color: #ffffff; 
  background-color: #5a81d4; 
  text-align: center; 
  width: 100%; 
  margin-bottom: 10px;
  padding: 1%;
}

.statsNguts__player_card_stats {
  height: auto;
  display: flex;
  flex-direction: row;
  position: relative;
}

.statsNguts__player_card_stats_tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  margin-left: 10px;
  flex: 0 0 15%;
}

.statsNguts__player_card_stats_tabs_button {
  margin-bottom: 8px;
  padding: 12px 20px;
  border: none;
  background-color: #292e31;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: 100%;
  font-size: 12px;
}

.statsNguts__player_card_stats_tabs_button.active {
  background-color: #245726;
}

.statsNguts__player_card_stats_details {
  flex: 1;
  margin-right: 10px;
}

.statsNguts__player_card_stats_details_table {
  width: 100%;
  table-layout: auto;
}

.statsNguts__player_card_stats_details_table table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
}
  
.statsNguts__player_card_stats_details_table th {
  background-color: #87b689;
  border-bottom: 2px solid #ddd;
  text-align: center;
  padding: 12px;
  font-size: 14px;
}
  
.statsNguts__player_card_stats_details_table th:first-child {
  border-top-left-radius: 10px;
}
  
.statsNguts__player_card_stats_details_table th:last-child {
  border-top-right-radius: 10px;
}
  
.statsNguts__player_card_stats_details_table td {
  padding: 12px;
  background-color: #cddff0;
  text-align: center;
  word-break: break-all;
  white-space: nowrap;
  font-size: 12px;
}

.statsNguts__player_card_stats_details_table tr:hover {
  background-color: #0f2016;
}
  
.statsNguts__player_card_stats_details_table_entry {
  font-weight: normal;
}

@media screen and (max-width: 1800px) {
  .statsNguts__player_card {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .statsNguts__player_card_avatarContainer {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
  }

  .statsNguts__player_card_content {
    width: 100%;
  }

  .statsNguts__player_card_stats {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
  }

  .statsNguts__player_card_stats_tabs {
    flex-direction: column; 
    align-items: center;
    width: auto;
  }

  .statsNguts__player_card_stats_tabs_button {
    width: 100%;
    margin-bottom: 8px;
  }

  .statsNguts__player_card_stats_details {
    width: 100%;
    overflow-x: auto;
  }

  .statsNguts__player_card_stats_details_table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
  }

  .statsNguts__player_card_stats_details_table td {
    font-size: 10px;
    padding: 7px;
    white-space: nowrap;
  }

  .statsNguts__player_title h3 {
    font-size: 18px;
  }

  .statsNguts__player_title h5 {
    font-size: 16px;
  }
  
  .statsNguts__player_card_name h4 {
    font-size: 18px;
  }
  
  .statsNguts__player_card_country h4 {
    font-size: 18px;
  }
  
  .statsNguts__player_card_price h4 {
    font-size: 18px;
  }
  
  .statsNguts__player_card_batting_style h4 {
    font-size: 18px;
  }
  
  .statsNguts__player_card_bowling_style h4 {
    font-size: 18px;
  }

  .statsNguts__player_card_stats_container h1 {
    font-size: 18px;
  }

  .statsNguts__player_card_player_overview h4 {
    font-size: 18px;
  }

  .statsNguts__player_card_player_trends h4 {
    font-size: 18px;
  }

  .statsNguts__player_card_player_availability.player-unavailable h4 {
    font-size: 18px;
  }

  .statsNguts__player_card_player_availability.player-available h4 {
    font-size: 18px;
  }
}
  
@media screen and (max-width: 768px) {
  .statsNguts__player_card {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .statsNguts__player_card_avatarContainer {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
  }

  .statsNguts__player_card_content {
    width: 100%;
  }

  .statsNguts__player_card_stats {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
  }

  .statsNguts__player_card_stats_tabs {
    flex-direction: column; 
    align-items: center;
    width: auto;
  }

  .statsNguts__player_card_stats_tabs_button {
    width: 100%;
    margin-bottom: 8px;
  }

  .statsNguts__player_card_stats_details {
    width: 100%;
    overflow-x: auto;
  }

  .statsNguts__player_card_stats_details_table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
  }

  .statsNguts__player_card_stats_details_table td {
    font-size: 10px;
    padding: 7px;
    white-space: nowrap;
  }

  .statsNguts__player_title h3 {
    font-size: 18px;
  }

  .statsNguts__player_title h5 {
    font-size: 16px;
  }
  
  .statsNguts__player_card_price h4 {
    font-size: 18px;
  }
  
  .statsNguts__player_card_batting_style h4 {
    font-size: 18px;
  }
  
  .statsNguts__player_card_bowling_style h4 {
    font-size: 18px;
  }

  .statsNguts__player_card_stats_container h1 {
    font-size: 18px;
  }

  .statsNguts__player_card_player_overview h4 {
    font-size: 18px;
  }

  .statsNguts__player_card_player_trends h4 {
    font-size: 18px;
  }

  .statsNguts__player_card_player_availability.player-unavailable h4 {
    font-size: 18px;
  }

  .statsNguts__player_card_player_availability.player-available h4 {
    font-size: 18px;
  }
}
  
@media screen and (max-width: 480px) {
  .statsNguts__player_card_stats_details_table th {
    font-size: 11px;
    padding: 10px;
  } 

  .statsNguts__player_card_stats_details_table td {
    font-size: 10px;
    padding: 7px;
    white-space: nowrap;
  }

  .statsNguts__player_title h3 {
    font-size: 16px;
  }

  .statsNguts__player_title h5 {
    font-size: 14px;
  }
  
  .statsNguts__player_card_price h4 {
    font-size: 16px;
  }
  
  .statsNguts__player_card_batting_style h4 {
    font-size: 16px;
  }
  
  .statsNguts__player_card_bowling_style h4 {
    font-size: 16px;
  }

  .statsNguts__player_card_player_overview h4 {
    font-size: 16px;
  }

  .statsNguts__player_card_player_trends h4 {
    font-size: 16px;
  }

  .statsNguts__player_card_stats_container h1 {
    font-size: 16px;
  }

  .statsNguts__player_card_player_availability.player-unavailable h4 {
    font-size: 16px;
  }

  .statsNguts__player_card_player_availability.player-available h4 {
    font-size: 16px;
  }
}