/* 📌 Fantasy Team Selection Container */
.statsNguts__fantasyTeamCustom {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
  margin-top: 20px; /* Reduced margin for compact view */
  padding-bottom: 40px; /* Adjusted bottom padding */
}

/* 🏏 Title */
.statsNguts__fantasyTeamCustom h2 {
  font-size: 32px;
  color: #004d99;
  margin-bottom: 10px;
  text-align: center;
}

/* 📌 Main Selection Container */
.statsNguts__fantasyTeamCustom_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  padding: 5px;
  width: 100%;
  position: relative;
  z-index: 2;
}

/* 🏏 Each Role Section */
.statsNguts__fantasyTeamCustom_container_team {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Soft glow effect */
  flex-basis: calc(25% - 8px);
  margin-bottom: 5px;
  text-align: center;
  padding: 8px;
  min-height: 110px;
  backdrop-filter: blur(10px); /* ✨ Frosted Glass Effect */
}

/* 🎭 Role Title */
.statsNguts__fantasyTeamCustom_container_team_section h3 {
  color: #333;
  font-size: 0.8rem; /* Adjusted for readability */
  margin: 10px 0 5px;
  font-weight: bold;
}

/* 🏏 Players Grid */
.statsNguts__fantasyTeamCustom_container_players {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px; /* Reduced gap */
}

/* 📌 Individual Player Card */
.statsNguts__fantasyTeamCustom_container_player_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: calc(25% - 8px);
  max-width: calc(25% - 8px);
  padding: 4px;
  border-radius: 6px;
  transition: transform 0.2s ease-in-out;
  min-height: 90px; /* Fixed height */
  text-align: center; /* Ensure text stays inside */
}

/* 🌟 Hover Effect */
.statsNguts__fantasyTeamCustom_container_player_item:hover {
  transform: scale(1.03);
}

/* 📸 Player Avatar */
.statsNguts__fantasyTeamCustom_container_player_avatar {
  width: 50px;
  height: 65px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 3px;
  border: 2px solid #004d99;
}

/* 📛 Player Name */
.statsNguts__fantasyTeamCustom_container_player_info
  .statsNguts__fantasyTeamCustom_container_player_name {
  font-size: 11px;
  color: #007bff;
  font-weight: bold;
  text-align: center;
  white-space: normal; /* 🟢 Allows name to wrap to the next line */
  word-wrap: break-word; /* Ensures it doesn’t overflow */
  line-height: 1.2; /* Adjust spacing */
  max-width: 100%; /* Keeps within card */
  padding: 0 3px; /* Adds slight padding */
}

/* ⏳ Empty Slot */
.empty-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 65px;
  border-radius: 8px;
  background-color: rgba(200, 200, 200, 0.2);
  font-size: 10px;
  color: #999;
  border: 2px dashed #ccc;
}

/* 🚀 Final Message Section */
.final-message-container {
  text-align: center;
  margin-top: 10px;
  padding-bottom: 30px;
}

.final-message {
  font-size: 16px;
  font-weight: bold;
  color: #28a745;
}

/* 🔄 Reset Button */
.btn-reset {
  background: #007bff;
  color: white;
  margin-top: 6px;
  padding: 8px;
  font-size: 12px;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-reset:hover {
  background: #0056b3;
}

/* 📌 RESPONSIVE DESIGN */
@media (max-width: 1024px) {
  .statsNguts__fantasyTeamCustom_container_team {
    flex-basis: calc(50% - 8px);
  }
}

@media (max-width: 768px) {
  .statsNguts__fantasyTeamCustom {
    padding-bottom: 80px;
  }

  .statsNguts__fantasyTeamCustom h2 {
    font-size: 26px; /* Adjusted title size */
  }

  .statsNguts__fantasyTeamCustom_container_team {
    flex-basis: 100%;
    min-height: 100px; /* Reduced height */
  }

  .statsNguts__fantasyTeamCustom_container {
    padding: 0px;
  }

  .statsNguts__fantasyTeamCustom_container_team_section h3 {
    font-size: 0.7rem; /* Smaller headings */
  }

  .statsNguts__fantasyTeamCustom_container_player_name {
    font-size: 10px; /* Adjusted for small screens */
    white-space: normal; /* Ensures wrapping */
    line-height: 1.1;
  }

  .statsNguts__fantasyTeamCustom_container_player_avatar {
    width: 45px;
    height: 60px;
  }

  .empty-slot {
    width: 45px;
    height: 60px;
    font-size: 9px;
  }
}

@media (max-width: 480px) {
  .statsNguts__fantasyTeamCustom {
    padding-bottom: 100px;
  }

  .statsNguts__fantasyTeamCustom h2 {
    font-size: 22px; /* Further reduced for small screens */
  }

  .statsNguts__fantasyTeamCustom_container_team {
    flex-basis: 100%;
    min-height: 90px;
    padding: 6px;
  }

  .statsNguts__fantasyTeamCustom_container {
    padding: 0px;
  }

  .statsNguts__fantasyTeamCustom_container_team_section h3 {
    font-size: 0.65rem; /* Adjusted for better readability */
  }

  .statsNguts__fantasyTeamCustom_container_player_name {
    font-size: 9px; /* Smaller font */
    white-space: normal;
    word-wrap: break-word;
    line-height: 1.1;
  }

  .statsNguts__fantasyTeamCustom_container_player_avatar {
    width: 38px;
    height: 50px;
  }

  .empty-slot {
    width: 38px;
    height: 50px;
    font-size: 8px;
  }

  .btn-reset {
    font-size: 11px;
    padding: 6px;
  }
}

/* 🔄 Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
