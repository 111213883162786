.statsNguts__playerMatchup {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 10px;
  margin: 20px auto; 
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  background-color: #ffffff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.333% - 20px); 
  font-family: var(--font-family);
}

.statsNguts__playerMatchup:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.highlight {
  border: 2px solid #ffcc00; 
  background-color: #ffffff; 
  box-shadow: 0 0 8px #ffcc00; 
}

.statsNguts__playerMatchup_header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.statsNguts__playerMatchup_imageWrapper {
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.statsNguts__playerMatchup_image {
  max-width: 100%; 
  height: auto; 
  object-fit: cover; 

  max-height: 150px; 
}

.statsNguts__playerMatchup_name {
  margin-top: 8px; 
  text-align: center;
  font-size: 16px; 
  color: #333;
  width: 90%; 
  white-space: nowrap; 
  overflow: hidden; 
  white-space: nowrap;
}

.statsNguts__playerMatchup_vs {
  font-size: 32px;
  font-weight: bold;
  color: #FF5722;
  text-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000, 4px 4px 0px #000;
  z-index: 2;
  transition: transform 0.2s ease, text-shadow 0.2s ease;
}

.statsNguts__playerMatchup_vs:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.statsNguts__playerMatchup_stats {
  width: 100%;
  background: #93b3ce;
  padding: 10px;
  border-radius: 8px;
  margin-top: 15px; 
}

.statsNguts__playerMatchup_stats table {
  width: 100%;
  border-collapse: collapse;
}

.statsNguts__playerMatchup_stats th, .statsNguts__playerMatchup_stats td {
  padding: 12px;
  border-bottom: 2px solid #eee;
  text-align: center;
}

.statsNguts__playerMatchup_stats th {
  background-color: #245726;
  color: white;
  font-weight: 600;
}

.statsNguts__playerMatchup_stats td {
  color: #333;
  font-weight: 400;
}

.statsNguts__playerMatchup_stats tr:hover {
  background-color: #f5f5f5;
}

.statsNguts__playerMatchup_stats tr:last-child td {
  border-bottom: none;
}

@media (max-width: 1024px) {
  .statsNguts__playerMatchup {
    width: calc(50% - 20px); 
  }
  .statsNguts__playerMatchup_image {
    height: 130px; 
  }
  .statsNguts__playerMatchup_vs {
    margin: 0 25px; 
    font-size: 28px;
  }
  .statsNguts__playerMatchup_name {
    font-size: 12px; 
  }
}

@media (max-width: 768px) {
  .statsNguts__playerMatchup {
    width: calc(50% - 20px); 
  }
  .statsNguts__playerMatchup_image {
    height: 120px; 
  }
  .statsNguts__playerMatchup_vs {
    margin: 0 20px;
    font-size: 26px;
  }
  .statsNguts__playerMatchup_name {
    font-size: 12px; 
  }
  .statsNguts__playerMatchup_stats th, .statsNguts__playerMatchup_stats td {
    padding: 10px; 
  }
}

@media (max-width: 480px) {
  .statsNguts__playerMatchup {
    width: calc(100% - 20px); 
  }
  .statsNguts__playerMatchup_image {
    height: 100px; 
  }
  .statsNguts__playerMatchup_vs {
    margin: 0 15px;
    font-size: 24px;
  }
  .statsNguts__playerMatchup_name {
    font-size: 10px; 
  }
  .statsNguts__playerMatchup_stats table {
    font-size: 13px; 
  }
}
