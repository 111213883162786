/* General Page Styling */
.player-details-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Arial", sans-serif;
  color: #333;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Player Name */
.player-details-container h1 {
  font-size: 2rem;
  color: #004aad;
  margin-bottom: 10px;
}

/* Player Image */
.player-avatar {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #004aad;
  margin: 15px auto;
  display: block;
}

/* Player Basic Info */
.player-details-container p {
  font-size: 1.1rem;
  margin: 5px 0;
}

.player-details-container p strong {
  color: #444;
}

/* Overview */
.player-overview {
  text-align: left;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 6px;
  line-height: 1.6;
  font-size: 1rem;
  margin: 15px 0;
  border-left: 5px solid #004aad;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Section Headers */
.player-details-container h2 {
  font-size: 1.6rem;
  color: #004aad;
  margin: 20px 0;
  border-bottom: 3px solid #004aad;
  display: inline-block;
  padding-bottom: 5px;
}

/* Stats Section */
.player-stats {
  background: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.player-stats h3 {
  font-size: 1.4rem;
  color: #ff5722;
  margin-bottom: 10px;
}

.player-stats ul {
  list-style: none;
  padding: 0;
}

.player-stats li {
  background: #f1f1f1;
  padding: 10px;
  margin: 8px 0;
  border-radius: 5px;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.player-stats li:hover {
  background: #ddd;
}

/* Responsive Design */
@media (max-width: 1080px) {
  .player-details-container {
    max-width: 800px;
    padding: 15px;
  }

  .player-details-container h1 {
    font-size: 1.8rem;
  }

  .player-avatar {
    width: 160px;
    height: 160px;
  }

  .player-overview {
    font-size: 1rem;
  }

  .player-stats h3 {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .player-details-container {
    max-width: 90%;
    padding: 10px;
  }

  .player-avatar {
    width: 140px;
    height: 140px;
  }

  .player-details-container h1 {
    font-size: 1.5rem;
  }

  .player-overview {
    font-size: 0.95rem;
    padding: 10px;
  }

  .player-stats li {
    font-size: 0.9rem;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .player-details-container {
    max-width: 95%;
    padding: 5px;
  }

  .player-avatar {
    width: 120px;
    height: 120px;
  }

  .player-details-container h1 {
    font-size: 1.2rem;
  }

  .player-overview {
    font-size: 0.9rem;
    padding: 8px;
  }

  .player-stats h3 {
    font-size: 1.1rem;
  }

  .player-stats li {
    font-size: 0.85rem;
    padding: 8px;
  }
}
