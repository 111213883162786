.statsNguts__brand {
  width: 100%;
  font-family: var(--font-family);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statsNguts__brand_heading {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 20px;
}

.statsNguts__brand_heading h1 {
  font-size: 48px;
}

.statsNguts__brand_heading h3 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 5rem;
}

.statsNguts__brand_image_container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.brand_image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand_image img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1024px) {
  .statsNguts__brand_heading h1 {
    font-size: 48px;
  }
  
  .statsNguts__brand_heading h3 {
    font-size: 20px;
  }

  .statsNguts__brand_image_container img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__brand {
    padding: 0 20px;
  }

  .statsNguts__brand_image_container {
    flex-direction: column;
    align-items: center;
  }

  .statsNguts__brand_heading h1 {
    font-size: 36px;
  }
  
  .statsNguts__brand_heading h3 {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__brand_heading h1 {
    font-size: 24px;
  }
  
  .statsNguts__brand_heading h3 {
    font-size: 12px;
  }

  .statsNguts__brand_image_container {
    flex-direction: column;
    align-items: center;
  }
}