.statsNguts__fantasyQuestionnaire {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 20px;
  font-family: var(--font-family);
}

.statsNguts__fantasyQuestionnaire_heading {
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 20px;
}

.statsNguts__fantasyQuestionnaire_heading h1 {
  font-size: 62px;
  line-height: 75px;
  font-family: var(--font-family);
  font-weight: 800;
}

.statsNguts__fantasyQuestionnaire_heading h3 {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  color: #fff;
  margin-bottom: 5rem;
}

@media screen and (max-width: 1024px) {
  .statsNguts__fantasyQuestionnaire_heading h1 {
    font-size: 46px;
    line-height: 52px;
  }

  .statsNguts__fantasyQuestionnaire_heading h3 {
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__fantasyQuestionnaire_heading h1 {
    font-size: 46px;
    line-height: 52px;
  }

  .statsNguts__fantasyQuestionnaire_heading h3 {
    font-size: 16px;
    line-height: 22px;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__fantasyQuestionnaire_heading h1 {
    font-size: 24px;
    line-height: 42px;
  }

  .statsNguts__fantasyQuestionnaire_heading h3 {
    font-size: 12px;
    line-height: 16px;
  }
}