.fantasyTeams__questions_and_answers_table {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
  margin-bottom: 40px;
}

.fantasyTeams__questions_and_answers_table h2 {
  font-size: 44px;
  color: #004d99;
  margin-bottom: 20px;
}

.fantasyTeams__questions_and_answers_table table {
  width: 100%;
  border-collapse: collapse;
}

.fantasyTeams__questions_and_answers_table th,
.fantasyTeams__questions_and_answers_table td {
  padding: 16px;
  border: 1px solid #e0e0e0;
  text-align: left;
  color: #333;
}

.fantasyTeams__questions_and_answers_table th {
  background-color: #004d99;
  color: #fff;
  font-weight: bold;
}

.fantasyTeams__questions_and_answers_table tbody tr {
  background-color: #f5f5f5;
}

.fantasyTeams__questions_and_answers_table tbody tr:hover {
  background-color: #e0e0e0;
}

.statsNguts__fantasyTeams {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family);
  margin-top: 40px;
}

.statsNguts__fantasyTeams h2 {
  font-size: 44px;
  color: #004d99;
  margin-bottom: 20px;
  text-align: center;
}

.statsNguts__fantasyTeams_Disclaimer {
  font-size: 12px;
  color: #F2F2EF;
  text-align: center;
}

.statsNguts__fantasyTeams_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
  width: 100%;
}

.statsNguts__fantasyTeams_container_team {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  flex-basis: calc(25% - 20px);
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: center;
}

.statsNguts__fantasyTeams_container_team_name {
  background-color: #004d99;
  color: #fff;
  padding: 10px 0;
  font-size: 1.25rem;
  font-weight: bold;
  width: 100%;
}

.statsNguts__fantasyTeams_container_team_section h3 {
  color: #333;
  font-size: 0.8rem;
  margin: 20px 0 10px;
  text-align: center;
}

.statsNguts__fantasyTeams_container_players {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
}

.statsNguts__fantasyTeams_container_player_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: calc(25% - 10px);
  max-width: calc(25% - 10px);
}

.statsNguts__fantasyTeams_container_player_avatar {
  width: 60px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 5px;
}

.statsNguts__fantasyTeams_container_player_info .statsNguts__fantasyTeams_container_player_name {
  font-size: 14px;
  color: #007bff;
}

.statsNguts__fantasyTeams_container_player_info .statsNguts__fantasyTeams_container_player_role_captain {
  font-size: 0.75rem;
  font-weight: bold;
  color: #28a745;
  margin-top: 2px;
}

.statsNguts__fantasyTeams_container_player_info .statsNguts__fantasyTeams_container_player_role_vice-captain {
  font-size: 0.75rem;
  font-weight: bold;
  color: #28a745;
  margin-top: 2px;
}

.statsNguts__fantasyTeams_button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .statsNguts__fantasyTeams_container_team {
    flex-basis: calc(50% - 20px);
  }

  .fantasyTeams__questions_and_answers_table th,
  .fantasyTeams__questions_and_answers_table td {
    padding: 16px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .statsNguts__fantasyTeams_container_team {
    flex-basis: 100%;
  }

  .statsNguts__fantasyTeams_container {
    padding: 0px;
  }

  .statsNguts__fantasyTeams_container_team_name {
    font-size: 1.0rem;
  }

  .statsNguts__fantasyTeams_container_team_section h3 {
    color: #333;
    font-size: 0.8rem;
    margin: 10px 0 5px;
  }
  
  .statsNguts__fantasyTeams_container_players {
    gap: 5px;
  }
  
  .statsNguts__fantasyTeams_container_player_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: calc(25% - 5px);
    max-width: calc(25% - 5px);
  }
  
  .statsNguts__fantasyTeams_container_player_avatar {
    width: 40px;
    height: 60px;
    margin-bottom: 2px;
  }
  
  .statsNguts__fantasyTeams_container_player_info .statsNguts__fantasyTeams_container_player_name {
    font-size: 12px;
  }

  .fantasyTeams__questions_and_answers_table th,
  .fantasyTeams__questions_and_answers_table td {
    padding: 14px;
    font-size: 14px;
  }

  .statsNguts__fantasyTeams_Disclaimer {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .statsNguts__fantasyTeams_container_team {
    flex-basis: 100%;
  }

  .statsNguts__fantasyTeams_container {
    padding: 0px;
  }

  .statsNguts__fantasyTeams_container_team_name {
    font-size: 1.0rem;
  }

  .statsNguts__fantasyTeams_container_team_section h3 {
    color: #333;
    font-size: 0.8rem;
    margin: 20px 0 10px;
  }
  
  .statsNguts__fantasyTeams_container_players {
    gap: 5px;
  }
  
  .statsNguts__fantasyTeams_container_player_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: calc(25% - 5px);
    max-width: calc(25% - 5px);
  }
  
  .statsNguts__fantasyTeams_container_player_avatar {
    width: 40px;
    height: 60px;
    margin-bottom: 2px;
  }
  
  .statsNguts__fantasyTeams_container_player_info .statsNguts__fantasyTeams_container_player_name {
    font-size: 12px;
  }
  
  .fantasyTeams__questions_and_answers_table h2 {
    font-size: 24px;
  }
  
  .fantasyTeams__questions_and_answers_table th,
  .fantasyTeams__questions_and_answers_table td {
    padding: 12px;
    font-size: 12px;
  }

  .statsNguts__fantasyTeams h2 {
    font-size: 24px;
  }

  .statsNguts__fantasyTeams_Disclaimer {
    font-size: 8px;
  }
}
