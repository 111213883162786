.statsNguts__teamh2h_matchups {
    text-align: left;
    word-wrap: break-word;
    font-family: var(--font-family);
    margin: 10px;
    padding: 10px;
  }
  
  .statsNguts__teamh2h_matchups h3 {
    font-size: 14px;
    color: #666666;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  .statsNguts__teamh2h_matchups_toggle {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: #ffffff;
    background-color: #5a81d4;
    padding: 1%;
  }
  
  .statsNguts__teamh2h_matchups_toggle:focus {
    outline: none;
  }

  .statsNguts__teamh2h_matchupsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Ensures items are evenly spaced */
    gap: 20px; /* Space between the cards */
  }
  
  @media screen and (max-width: 1024px) {
    .statsNguts__teamh2h_matchups_toggle {
      font-size: 18px;
    }
    
    .statsNguts__teamh2h_matchups_toggle h3 {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .statsNguts__teamh2h_matchups_toggle {
      font-size: 18px;
    }
    
    .statsNguts__teamh2h_matchups h3 {
      font-size: 12px;
    } 
  }
    
  @media screen and (max-width: 480px) {
    .statsNguts__teamh2h_matchups_toggle {
      font-size: 16px;
    }
    
    .statsNguts__teamh2h_matchups h3 {
      font-size: 10px;
    }
  }
  