.statsNguts__player_overviewContainer {
  text-align: left;
  word-wrap: break-word;
  font-family: var(--font-family);
  margin: 10px;
  padding: 10px;
}

.statsNguts__player_overviewContainer h3 {
  font-size: 14px;
  color: #666666;
  margin-bottom: 5px;
  margin-top: 5px;
}

.overviewToggle {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  font-size: 20px;
  color: #ffffff;
  background-color: #5a81d4;
  padding: 1%;
}

.overviewToggle:focus {
  outline: none;
}

@media screen and (max-width: 1024px) {
  .overviewToggle {
    font-size: 18px;
  }
  
  .statsNguts__player_overviewContainer h3 {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .overviewToggle {
    font-size: 18px;
  }
  
  .statsNguts__player_overviewContainer h3 {
    font-size: 12px;
  }
}
  
@media screen and (max-width: 480px) {
  .overviewToggle {
    font-size: 16px;
  }
  
  .statsNguts__player_overviewContainer h3 {
    font-size: 10px;
  }
}
