/* 🌟 Wrapper for Heading & Cards */
.statsNguts__fantasyCardsWrapper {
  text-align: center;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center; /* ✅ Ensures heading and cards are centered */
}

/* 🏏 Heading Section */
.statsNguts__fantasyCardsHeading {
  margin-bottom: 20px;
  padding: 10px;
  background: linear-gradient(135deg, #001f3f, #004080);
  border-radius: 8px;
  color: #fff;
  text-align: center;
  width: 100%;
  max-width: 800px; /* ✅ Prevents stretching */
}

.statsNguts__fantasyCardsHeading h1 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #00ccff;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
}

/* 🌟 Centered Layout for Any Number of Cards */
.statsNguts__fantasyCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* ✅ Always centers cards */
  align-items: center;
  width: 100%;
  max-width: 1200px; /* ✅ Prevents full-screen stretching */
  margin: 0 auto;
}

/* 🌐 Large Screens (1024px+) - Max 4 Cards Per Row */
@media (min-width: 1024px) {
  .statsNguts__fantasyCardsContainer {
    justify-content: center; /* ✅ Centers the cards */
  }
}

/* 🌐 Medium Screens (768px - 1024px) - 2 Cards Per Row */
@media (max-width: 1024px) {
  .statsNguts__fantasyCardsContainer {
    flex-wrap: wrap;
    justify-content: center; /* ✅ Keeps cards centered */
  }
}

/* 📱 Mobile Adjustments (480px - 768px) - 1 Card Per Row (Full Width) */
@media (max-width: 768px) {
  .statsNguts__fantasyCardsContainer {
    flex-direction: column; /* ✅ Stacks cards vertically */
    align-items: center;
    max-width: 100%; /* ✅ Ensures full width */
    padding: 0 10px;
  }

  .statsNguts__fantasyCardsContainer > .statsNguts__fantasyCard {
    width: 100%; /* ✅ Ensures card takes full available width */
  }

  .statsNguts__fantasyCardsHeading h1 {
    font-size: 1.5rem;
  }
}

/* 📲 Small Mobile Adjustments (Below 480px) */
@media (max-width: 480px) {
  .statsNguts__fantasyCardsContainer {
    max-width: 100%; /* ✅ Full width */
  }

  .statsNguts__fantasyCardsHeading h1 {
    font-size: 1.3rem;
  }
}
