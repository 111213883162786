.statsNguts__team_statsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 80%;
  margin: 0 auto; /* Center align the container */
}

.statsNguts__team_stats {
  flex: 1 1 calc(50% - 20px);
  margin-bottom: 20px;
  margin-right: 20px;
}

.statsNguts__team_stats:last-child {
  margin-right: 0;
}

.statsNguts__team_headerContainer {
  text-align: center;
}

.statsNguts__team_headerButton {
  background-color: #092a4e;
  color: #fff; 
  border: none; 
  padding: 10px 20px; 
  font-size: 18px; 
  cursor: pointer; 
  border-radius: 5px;
  margin-bottom: 20px; 
}

.statsNguts__team_playerName {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.statsNguts__team_firstStat {
  color: green;
  font-weight: bold;
  font-size: 14px;
}

.statsNguts__team_otherStats {
  font-size: 12px;
}

.statsNguts__team_statContainer {
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.statsNguts__team_playerStatContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.statsNguts__team_playerImageContainer {
  width: 100px; 
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.statsNguts__team_playerImage {
  width: 100%; 
  height: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.statsNguts__team_playerImage img {
  width: auto; 
  height: 100%;
  object-fit: cover;
}

.statsNguts__team_statInfo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.statsNguts__team_statInfo p {
  margin: 0;
  padding: 0;
}

.statsNguts__team_statHeader {
  background-color: #5a81d4;
  color: #fff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media screen and (max-width: 1080px) {
  .statsNguts__team_stats {
    flex: 1 1 calc(50% - 20px);
  }

  .statsNguts__team_statsContainer {
    max-width: 100%;
  }

  .statsNguts__team_headerButton {
    font-size: 14px; 
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__team_statsContainer {
    flex-direction: column;
    max-width: 100%;
  }
  
  .statsNguts__team_stats {
    flex: 1 1 calc(100% - 20px);
    margin-right: 0;
  }

  .statsNguts__team_headerButton {
    font-size: 14px; 
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__team_stats {
    flex: 1 1 calc(100% - 20px);
    margin-right: 0;
  }

  .statsNguts__team_statsContainer {
    max-width: 100%;
  }

  .statsNguts__team_headerButton {
    font-size: 16px; 
  }
}
