.statsNguts__matchStats {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
}

.statsNguts__matchStats_info {
  display: flex;
  flex-direction: row;
  gap: 1rem; 
  margin-bottom: 2rem;
}

.statsNguts__matchStats_avatarContainer,
.statsNguts__matchStats_headingContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.statsNguts__matchStats_avatarContainer_avatar {
  max-width: 100%;
  height: auto;
}

.statsNguts__matchStats_headingBox {
    background-color: #222027;
    padding: 2rem;
    margin: 1rem 0;
    border: 2px solid #fff;
    border-radius: 10px;
    width: 100%; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #fff; 
    background: linear-gradient(135deg, #252425, #16292b);
}

.statsNguts__matchStats_headingBox h1,
.statsNguts__matchStats_headingBox h2,
.statsNguts__matchStats_headingBox h3,
.statsNguts__matchStats_headingBox h4 {
  margin: 0;
  text-align: center;
}

.statsNguts__matchStats_headingBox {
  background-color: #222027;
  padding: 2rem;
  border-radius: 10px;
  width: 80%; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff; 
  text-align: center;
}

.statsNguts__matchStats_headingBox h1 {
  font-size: 36px;
  line-height: 75px;
  font-weight: 800;
  margin-bottom: 10px;
}

.statsNguts__matchStats_headingBox h2 {
  font-size: 22px;
  font-weight: 800;
  line-height: 30.30px;
  color: #774dd1;
  margin-bottom: 10px;
}

.statsNguts__matchStats_headingBox h3 {
  font-size: 18px;
  font-weight: 800;
  line-height: 30.30px;
  color: #a6b4e0;
  margin-bottom: 10px;
}

.statsNguts__matchStats_headingBox h4 {
  font-size: 16px;
  font-weight: 800;
  line-height: 30.30px;
  color: #a6b4e0;
  margin-bottom: 10px;
}

.statsNguts__matchStats_finalizedBanner {
  padding: 15px;
  background-color: #373807;
  color: white;
  text-align: center;
  border-radius: 5px;
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold; 
  animation: pulse 1s infinite;
}

.statsNguts__matchStats_buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.statsNguts__matchStats_buttons_stats_buttons button {
  background-color: #774dd1;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  margin: 0 8px;
}

.statsNguts__matchStats_buttons_generateFantasy_buttons button {
  background-color: #090986;
  color: #a974d4;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  margin: 0 8px;
}

.statsNguts__matchStats_buttons_generateFantasy_buttons h5 {
  color: #e1e7df;
  font-size: 12px;
}

.statsNguts__matchStats_buttons_bestPlayingEleven_buttons button {
  background-color: #418b47;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  margin: 0 8px;
}

.statsNguts__matchStats_buttons button:hover {
  background-color: #075213;
}

.statsNguts__matchStats_buttons_stats_buttons {
  color: #d5cfcf;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 20px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.statsNguts__matchStats_buttons_generateFantasy_buttons {
  color: #d5cfcf;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 20px 10px;
  transition: background-color 0.3s;
}

.statsNguts__matchStats_buttons_bestPlayingEleven_buttons {
  color: #d5cfcf;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 20px 10px;
  transition: background-color 0.3s;
}

.statsNguts__matchStats_buttons_postMatchAnalysisButton button {
  background-color: #1140c2;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  margin: 0 8px;
}

@media screen and (max-width: 1024px) {
  .statsNguts__matchStats_avatarContainer,
  .statsNguts__matchStats_headingContainer {
      flex-direction: column;
      align-items: flex-start;
  }

  .statsNguts__matchStats_headingBox {
    width: 100%; 
  }

  .statsNguts__matchStats_headingBox h1 {
    font-size: 18px;
    line-height: 20px;
  }

  .statsNguts__matchStats_headingBox h2 {
    font-size: 12px;
    line-height: 20px;
  }

  .statsNguts__matchStats_headingBox h3 {
    font-size: 12px;
    line-height: 20px;
  }

  .statsNguts__matchStats_headingBox h4 {
    font-size: 12px;
    line-height: 20px;
  }

  .statsNguts__matchStats_finalizedBanner {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__matchStats_avatarContainer,
  .statsNguts__matchStats_headingContainer {
      flex-direction: column;
      align-items: flex-start;
  }

  .statsNguts__matchStats_headingBox {
    width: 100%; 
  }

  .statsNguts__matchStats_headingBox h1 {
    font-size: 18px;
    line-height: 20px;
  }

  .statsNguts__matchStats_headingBox h2 {
    font-size: 14px;
    line-height: 20px;
  }

  .statsNguts__matchStats_headingBox h3 {
    font-size: 12px;
    line-height: 20px;
  }

  .statsNguts__matchStats_headingBox h4 {
    font-size: 12px;
    line-height: 20px;
  }

  .statsNguts__matchStats_buttons button {
    font-size: 12px;
    padding: 6px 12px;
    margin: 0 6px;
  }

  .statsNguts__matchStats_finalizedBanner {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__matchStats_info {
    flex-direction: column;
  }

  .statsNguts__matchStats_headingBox {
    width: 100%; 
  }

  .statsNguts__matchStats_headingBox h1 {
    font-size: 14px;
    line-height: 20px;
  }

  .statsNguts__matchStats_headingBox h2 {
    font-size: 10px;
    line-height: 20px;
  }

  .statsNguts__matchStats_headingBox h3 {
    font-size: 10px;
    line-height: 20px;
  }

  .statsNguts__matchStats_headingBox h4 {
    font-size: 14px;
    line-height: 20px;
  }

  .statsNguts__matchStats_buttons {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .statsNguts__matchStats_buttons button {
    width: 100%;
    margin: 8px 0;
  }

  .statsNguts__matchStats_finalizedBanner {
    font-size: 16px;
  }
}
