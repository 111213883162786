.statsNguts__header {
    display: flex;
    flex-direction: row;
    font-family: var(--font-family);
}

.statsNguts__header_content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.statsNguts__header_content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 56px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.statsNguts__header_content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.statsNguts__header_content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.statsNguts__header_content__people img {
    width: 181.79px;
    height: 38px;
}

.statsNguts__header_content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #ffffff;
    text-align: center;
}

.statsNguts__header_image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.statsNguts__header_image img {
    width: 100%;
}

.highlighted-text {
    font-weight: bold;
    color: #17dd70;
}  

@media screen and (max-width: 1024px) {
    .statsNguts__header {
        flex-direction: column;
    }

    .statsNguts__header_content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 768px) {
    .statsNguts__header_content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .statsNguts__header_content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .statsNguts__header_content__people {
        flex-direction: column;
    }

    .statsNguts__header_content__people p {
        margin: 0;
    }

    .statsNguts__header_content__people img {
        padding: 20;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 480px) {
    .statsNguts__header_content h1 {
        font-size: 24px;
        line-height: 42px;
    }

    .statsNguts__header_content h3 {
        font-size: 14px;
        line-height: 24px;
    }

    .statsNguts__header_content__people p {
        margin: 0 0 0 1rem;
        font-size: 12px;
        text-align: center;
    }
}