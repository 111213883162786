.statsNguts__matchStats {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
}

/* --- Match Info Section --- */
.statsNguts__matchStats_info {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;
}

.statsNguts__matchStats_headingContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* --- Heading Box Styling --- */
.statsNguts__matchStats_headingBox {
  background-color: #222027;
  padding: 1rem;
  margin: 1rem 0;
  border: 2px solid #fff;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
  background: linear-gradient(135deg, #252425, #16292b);
  text-align: center;
}

.statsNguts__matchStats_headingBox h1 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 10px;
}

.statsNguts__matchStats_headingBox h2 {
  font-size: 18px;
  font-weight: 400;
  color: #774dd1;
  margin-bottom: 10px;
}

.statsNguts__matchStats_headingBox h3,
.statsNguts__matchStats_headingBox h4 {
  font-size: 12px;
  font-weight: 400;
  color: #a6b4e0;
  margin-bottom: 5px;
}

/* --- Button Groups: Ensure All Buttons are Centered Properly --- */
.statsNguts__matchStats_buttons,
.statsNguts__matchStats_buttons_stats_buttons,
.statsNguts__matchStats_buttons_fantasyCards_buttons,
.statsNguts__matchStats_buttons_generateFantasy_buttons,
.statsNguts__matchStats_buttons_bestPlayingEleven_buttons,
.statsNguts__matchStats_buttons_postMatchAnalysisButton {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Ensures horizontal centering */
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 10px; /* Ensure uniform spacing */
  margin: 10px 0; /* Prevent buttons from sticking to edges */
}

/* --- Individual Button Styling --- */
.statsNguts__matchStats_buttons button {
  font-size: 16px;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
  min-width: 140px;
  width: auto;
}

.statsNguts__matchStats_buttons_stats_buttons button {
  background-color: #774dd1;
  color: #fff;
}

.statsNguts__matchStats_buttons_fantasyCards_buttons button {
  background-color: #666229;
  color: #fff;
}

.statsNguts__matchStats_buttons_generateFantasy_buttons button {
  background-color: #05056d;
  color: #a974d4;
  font-weight: bold;
}

.statsNguts__matchStats_buttons_fantasyPrediction_buttons button {
  background-color: #418b47;
  color: #fff;
}

.statsNguts__matchStats_buttons_postMatchAnalysisButton button {
  background-color: #1140c2;
  color: #fff;
}

.statsNguts__matchStats_buttons button:hover {
  background-color: #075213;
}

/* --- Responsive Design Tweaks for Better Centering --- */

/* --- Tablet View (Up to 1024px) --- */
@media screen and (max-width: 1024px) {
  .statsNguts__matchStats_headingContainer {
    flex-direction: column;
    align-items: center;
  }

  .statsNguts__matchStats_headingBox h1 {
    font-size: 24px;
  }

  /* Stack buttons in a single column */
  .statsNguts__matchStats_buttons_generateFantasy_buttons {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .statsNguts__matchStats_buttons_generateFantasy_buttons button {
    width: 80%;
    max-width: 280px;
    font-size: 14px;
  }
}

/* --- Mobile View (Up to 768px) --- */
@media screen and (max-width: 768px) {
  .statsNguts__matchStats_info {
    flex-direction: column;
    align-items: center;
  }

  .statsNguts__matchStats_headingBox h1 {
    font-size: 18px;
  }

  /* Make sure all buttons stay centered */
  .statsNguts__matchStats_buttons {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  .statsNguts__matchStats_buttons button {
    width: 90%;
    max-width: 300px;
    font-size: 14px;
    padding: 10px 12px;
  }

  .statsNguts__matchStats_buttons_generateFantasy_buttons {
    flex-direction: column;
    gap: 8px;
  }
}

/* --- Small Mobile View (Up to 480px) --- */
@media screen and (max-width: 480px) {
  .statsNguts__matchStats {
    padding: 10px;
  }

  .statsNguts__matchStats_headingBox {
    padding: 0.5rem;
    text-align: center;
  }

  .statsNguts__matchStats_headingBox h1 {
    font-size: 16px;
  }

  /* Ensure button groups and buttons are centered */
  .statsNguts__matchStats_buttons {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .statsNguts__matchStats_buttons button {
    width: 95%;
    max-width: 280px;
    text-align: center;
  }
}
