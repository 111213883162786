/* 📌 MATCH SUMMARY CARD */
.statsNguts__matchSummary_card {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
    font-family: var(--font-family);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds slight shadow for depth */
}

/* 📌 IMAGE CONTAINER */
.statsNguts__matchSummary_card_avatarContainer {
    width: 100%;
    height: 180px; /* Default height for desktop */
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-bg);
    overflow: hidden;
}

.statsNguts__matchSummary_card_avatarContainer_avatar {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures image fills container without distortion */
}

/* 📌 CONTENT CONTAINER */
.statsNguts__matchSummary_card_content {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center; /* Centers content for a clean look */
}

/* 📌 MATCH DETAILS */
.statsNguts__matchSummary_card_content h2 {
    font-size: 18px;
    font-weight: bold;
    color: #009688;
    margin-bottom: 5px;
}

.statsNguts__matchSummary_card_content p {
    font-size: 14px;
    font-weight: bold;
    color: #f5f5f5;
}

.statsNguts__matchSummary_card_content h3 {
    font-size: 14px;
    font-weight: bold;
    color: #17dd70;
    margin-top: 8px;
}

/* ✅ MEDIA QUERIES FOR RESPONSIVENESS */

/* 📌 Tablets (Medium Screens) */
@media screen and (max-width: 1024px) {
    .statsNguts__matchSummary_card_avatarContainer {
        height: 160px;
    }

    .statsNguts__matchSummary_card_content h2 {
        font-size: 16px;
    }

    .statsNguts__matchSummary_card_content p,
    .statsNguts__matchSummary_card_content h3 {
        font-size: 12px;
    }
}

/* 📌 Mobile Screens (Small Screens) */
@media screen and (max-width: 768px) {
    .statsNguts__matchSummary_card {
        border-radius: 8px;
    }

    .statsNguts__matchSummary_card_avatarContainer {
        height: 140px; /* Reduce image height */
    }

    .statsNguts__matchSummary_card_content {
        padding: 0.8rem;
    }

    .statsNguts__matchSummary_card_content h2 {
        font-size: 14px;
    }

    .statsNguts__matchSummary_card_content p,
    .statsNguts__matchSummary_card_content h3 {
        font-size: 12px;
    }
}

/* 📌 Small Mobile Screens */
@media screen and (max-width: 480px) {
    .statsNguts__matchSummary_card {
        border-radius: 5px;
    }

    .statsNguts__matchSummary_card_avatarContainer {
        height: 120px; /* Further reduce image height for small screens */
    }

    .statsNguts__matchSummary_card_content {
        padding: 0.5rem;
    }

    .statsNguts__matchSummary_card_content h2 {
        font-size: 12px;
        margin-bottom: 3px;
    }

    .statsNguts__matchSummary_card_content p,
    .statsNguts__matchSummary_card_content h3 {
        font-size: 10px;
    }
}