.statsNguts__seriesDescription_T20I {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
}

.statsNguts__seriesDescription_T20I_heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}

.statsNguts__seriesDescription_T20I_heading h1 {
    font-size: 56px;
    line-height: 75px;
    font-weight: 800;
}

@media screen and (max-width: 1200px) {
    .statsNguts__seriesDescription_T20I_heading h1 {
        font-size: 36px;
        line-height: 52px;
    }
}

@media screen and (max-width: 768px) {
    .statsNguts__seriesDescription_T20I_heading h1 {
        font-size: 32px;
        line-height: 52px;
    }
}

@media screen and (max-width: 480px) {
    .statsNguts__seriesDescription_T20I_heading h1 {
        font-size: 14px;
        line-height: 42px;
    }
}