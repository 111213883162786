.statsNguts__teamh2h_trends {
  text-align: left;
  word-wrap: break-word;
  font-family: var(--font-family);
  margin: 10px;
  padding: 10px;
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center;
  margin-bottom: 20px;
}

.statsNguts__teamh2h_trends h1 {
  font-size: 20px;
  color: #ffffff; 
  background-color: #9ab6f3; 
  text-align: center; 
  width: 100%; 
  margin-bottom: 10px;
  padding: 1%;
}

.statsNguts__teamh2h_chartContainer {
  width: 100%; 
  margin-bottom: 20px; 
}

.statsNguts__teamh2h_trends_toggle {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  font-size: 20px;
  color: #ffffff;
  background-color: #5a81d4;
  padding: 1%;
  margin-bottom: 20px;
}

.statsNguts__teamh2h_trends_toggle:focus {
  outline: none;
}

.statsNguts__teamh2h_statsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 2px solid rgb(12, 13, 80);
  border-radius: 10px; 
  padding: 10px; 
  max-width: 80%;
}

.statsNguts__teamh2h_statsTotalMatchesHeader {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background-color: #092a4e;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 10px;
}

.statsNguts__teamh2h_statsTotalMatchesContainer, 
.statsNguts__teamh2h_statsTeamSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.statsNguts__teamh2h_teamLogo {
  height: 100%; 
  object-fit: contain; 
}

.statsNguts__teamh2h_stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.statsNguts__teamh2h_stats_stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statsNguts__teamh2h_stats_statTitle {
  font-size: 16px;
  color: #333;
  font-weight: bold;
  margin-bottom: 4px;
  text-align: center;
  white-space: nowrap;
}

.statsNguts__teamh2h_stats_statValue {
  font-size: 40px;
  font-weight: bold;
  color: #007bff;
  text-align: center;
}

.statsNguts__teamh2h_vs {
  font-size: 60px;
  font-weight: bold;
  color: #666;
}

.statsNguts__teamh2h_card_teamStats {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .statsNguts__teamh2h_trends_toggle {
    font-size: 18px;
  }

  .statsNguts__teamh2h_trends {
      flex-direction: column; 
      align-items: stretch; 
  }

  .statsNguts__teamh2h_chartContainer {
      width: 100%; 
      margin: 0 0 20px; 
  }

  .statsNguts__teamh2h_trends h1 {
      font-size: 18px;
  }

  .statsNguts__teamh2h_statsTeamSection {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .statsNguts__teamh2h_teamLogo {
    width: 80%;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
  }

  .teamA .statsNguts__teamh2h_stats, 
  .teamB .statsNguts__teamh2h_stats {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .statsNguts__teamh2h_stats_statTitle {
    font-size: 14px;
  }

  .statsNguts__teamh2h_stats_statValue {
    font-size: 40px;
  }

  .statsNguts__teamh2h_statsWrapper {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__teamh2h_trends_toggle {
    font-size: 18px;
  }

  .statsNguts__teamh2h_trends {
      flex-direction: column; 
      align-items: stretch; 
  }

  .statsNguts__teamh2h_chartContainer {
      width: 100%;
      margin: 0 0 20px;
  }

  .statsNguts__teamh2h_trends h1 {
      font-size: 18px;
  }
  
  .statsNguts__teamh2h_statsTotalMatchesContainer,
  .statsNguts__teamh2h_statsTeamSection {
    flex-direction: column;
    align-items: center;
  }

  .statsNguts__teamh2h_stats {
    padding: 8px;
  }

  .statsNguts__teamh2h_stats_statTitle {
    font-size: 14px;
  }

  .statsNguts__teamh2h_stats_statValue {
    font-size: 30px;
  }

  .statsNguts__teamh2h_statsWrapper {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__teamh2h_trends_toggle {
    font-size: 16px;
  }

  .statsNguts__teamh2h_trends {
      flex-direction: column; 
      align-items: stretch; 
  }

  .statsNguts__teamh2h_chartContainer {
      width: 100%;
      margin: 0 0 20px; 
  }

  .statsNguts__teamh2h_trends h1 {
      font-size: 16px;
  }

  .statsNguts__teamh2h_teamLogo {
    width: 100%; 
    height: auto;
    object-fit: contain;
  }

  .statsNguts__teamh2h_stats_statTitle {
    font-size: 14px;
  }

  .statsNguts__teamh2h_stats_statValue {
    font-size: 30px;
  }

  .statsNguts__teamh2h_statsTotalMatchesHeader,
  .statsNguts__teamh2h_statsTotalMatchesContainer {
    padding: 10px;
    margin-bottom: 15px;
  }

  .statsNguts__teamh2h_statsTeamSection {
    flex-direction: column;
    align-items: center;
  }

  .teamB .statsNguts__teamh2h_stats {
    order: -1; 
  }

  .statsNguts__teamh2h_card_teamStats {
    flex-direction: column;
  }

  .statsNguts__teamh2h_statsWrapper {
    max-width: 100%;
  }
}