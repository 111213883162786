.statsNguts__earlyAccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
    font-family: var(--font-family);
}

.statsNguts__earlyAccess_heading {
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;
}

.statsNguts__earlyAccess_heading h1 {
    font-weight: 800;
    font-size: 56px;
    line-height: 75px;
}

.statsNguts__earlyAccess_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: 10rem;
    cursor: pointer;
    background-color: #052e0a;
}

.statsNguts__earlyAccess_btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
} 

@media screen and (max-width: 1024px) {
  .statsNguts__earlyAccess_popup button {
    font-size: 14px;
  }

  .statsNguts__earlyAccess_heading h1 {
    font-size: 56px;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__earlyAccess_popup button {
    font-size: 12px;
  }

  .statsNguts__earlyAccess_heading h1 {
    font-size: 56px;
  }
}

@media screen and (max-width: 480px) {
    .statsNguts__earlyAccess_heading h1 {
        font-size: 24px;
        line-height: 42px;
    }

    .statsNguts__earlyAccess_btn p {
        font-size: 12px;
        line-height: 20px;
    }
}
