.statsNguts__completedSeries {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
}

.statsNguts__completedSeries_heading {
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.statsNguts__completedSeries_heading h1 {
    font-size: 56px;
    line-height: 75px;
    font-weight: 800;
}

.statsNguts__completedSeries_container {
    display: flex;
    flex-direction: row;
}

.statsNguts__completedSeries_container_content {
    flex: 1;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 1024px) {
    .statsNguts__completedSeries_container {
        flex-direction: column-reverse;
    }

    .statsNguts__completedSeries_container_content {
        flex: 1;
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }

    .statsNguts__completedSeries_heading h1 {
        font-size: 36px;
        line-height: 52px;
    }
}

@media screen and (max-width: 768px) {
    .statsNguts__completedSeries_container_content {
        grid-template-columns: repeat(1, 1fr);
    }

    .statsNguts__completedSeries_heading h1 {
        font-size: 32px;
        line-height: 52px;
    }
}

@media screen and (max-width: 480px) {
    .statsNguts__completedSeries_heading h1 {
        font-size: 24px;
        line-height: 42px;
    }
}