/* Default styles for large screens */
.statsNguts__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    font-family: var(--font-family);
    font-size: 1.5rem;
    background: var(--color-primary-dark);
    box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
    border-radius: 8px;
}

.statsNguts__navbar_links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.statsNguts__navbar_links_logo {
    margin-right: 2rem;
}

.statsNguts__navbar_links_logo img {
    width: auto;
    max-width: 100px;
}

.statsNguts__navbar_links_container {
    display: flex;
    flex-direction: row;
}

.statsNguts__navbar_sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.statsNguts__navbar_links_container a,
.statsNguts__navbar_sign p,
.statsNguts__navbar_menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.6;
    text-transform: capitalize;
    margin: 0 0.5rem;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 0.5px;
}

.statsNguts__navbar_sign button,
.statsNguts__navbar_menu_container button {
    padding: 0.75rem 1.5rem;
    color: #fff;
    background: rgb(181, 110, 38);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.125rem;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
    transition: background 0.3s ease;
}

.statsNguts__navbar_sign button:hover,
.statsNguts__navbar_menu_container button:hover {
    background: #FF3D00;
}

.statsNguts__navbar_menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.statsNguts__navbar_menu_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    background: var(--color-footer);
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 50px;
    min-width: 220px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.statsNguts__navbar_menu_container p {
    margin: 1rem 0;
}

.statsNguts__navbar_menu_container_links_welcomeMessage {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.6;
    margin: 0 1rem;
}

.statsNguts__navbar_big_text {
    font-size: 1.5rem;
    color: #fff;
    font-family: var(--font-family);
}

@media screen and (max-width: 1048px) {
    .statsNguts__navbar {
        font-size: 1.25rem;
    }
    .statsNguts__navbar_links_container {
        display: none;
    }
    .statsNguts__navbar_menu {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .statsNguts__navbar {
        padding: 1.5rem;
        font-size: 1rem;
    }
    .statsNguts__navbar_big_text {
        font-size: 1.2rem;
    }
    .statsNguts__navbar_menu_container_links_welcomeMessage {
        font-size: 1.1rem;
        margin-bottom: 8px;
    }
}

@media screen and (max-width: 480px) {
    .statsNguts__navbar {
        padding: 1rem;
        font-size: 0.875rem;
    }
    .statsNguts__navbar_sign {
        display: none;
    }
    .statsNguts__navbar_big_text {
        font-size: 1rem;
    }
    .statsNguts__navbar_menu_container_links_welcomeMessage {
        font-size: 0.9rem;
        margin-bottom: 8px;
    }
}
