.statsNguts__news {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  font-family: var(--font-family);
}

.statsNguts__news:hover {
  background-color: #94df94;
}

.statsNguts__news_avatar_container {
  margin-right: 15px;
}

.statsNguts__news_avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.statsNguts__news_text_container {
  flex: 1;
}

.statsNguts__news_text {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.statsNguts__news_date {
  color: #888;
  font-size: 14px;
}

.statsNguts__news_text_read_more_link {
  color: #3498db;
  text-decoration: none;
  display: block;
  margin-top: 8px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.statsNguts__news_text_read_more_link:hover {
  color: #1a77c0;
}

@media screen and (max-width: 1024px) {
  .statsNguts__news {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__news_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__news {
    padding: 15px;
  }

  .statsNguts__news_text {
    font-size: 12px;
  }

  .statsNguts__news_text_read_more_link {
    margin-top: 5px;
    font-size: 12px;
  }
}
