.statsNguts__seriesTeamStats_ODI {
  text-align: center;
  margin-top: 40px;
  padding: 30px;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 32, 0.9),
    rgba(0, 0, 64, 0.95)
  );
  border-radius: 12px;
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  max-width: 1200px;
  margin: auto;
}

.statsNguts__seriesTeamStats_ODI_team_header {
  background: linear-gradient(45deg, #3f3e3a, #d4a686);
  padding: 15px;
  border-radius: 12px;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
  text-align: center;
}

.statsNguts__seriesTeamStats_ODI_team_details_container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

.statsNguts__seriesTeamStats_ODI_stats_section {
  margin: 2rem 0;
  text-align: center;
}

.statsNguts__seriesTeamStats_ODI_section_heading {
  background: linear-gradient(45deg, #3f3e3a, #d4a686);
  padding: 15px;
  border-radius: 12px;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
  text-align: center;
}

.statsNguts__seriesTeamStats_ODI_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.statsNguts__seriesTeamStats_ODI_card {
  background: linear-gradient(145deg, #1e1e2f, #2a2a3f);
  border-radius: 12px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.statsNguts__seriesTeamStats_ODI_card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 25px rgba(255, 223, 0, 0.5);
}

.statsNguts__seriesTeamStats_ODI_title {
  font-size: 20px;
  color: #ffd700;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.statsNguts__seriesTeamStats_ODI_details {
  margin-top: 1rem;
}

.statsNguts__seriesTeamStats_ODI_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
}

.statsNguts__seriesTeamStats_ODI_detail.highlight {
  background: rgba(255, 223, 0, 0.2);
  border: 2px solid #ffd700;
}

.statsNguts__seriesTeamStats_ODI_photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
}

.statsNguts__seriesTeamStats_ODI_player {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-left: 0.5rem;
}

.statsNguts__seriesTeamStats_ODI_value {
  font-size: 12px;
  color: #ddd;
}

.statsNguts__seriesTeamStats_ODI_team_info_card {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05)
  );
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  min-width: 140px;
  max-width: 180px;
  height: 200px;
  box-shadow: 0px 8px 16px rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
}

.statsNguts__seriesTeamStats_ODI_team_info_card:hover {
  transform: scale(1.08);
  box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.3);
}

.statsNguts__seriesTeamStats_ODI_team_info_title {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #00aeff;
  text-align: center;
  margin-bottom: 8px;
}

.statsNguts__seriesTeamStats_ODI_team_info_name {
  font-size: 1rem;
  color: white;
  font-weight: bold;
  text-align: center;
}

.statsNguts__seriesTeamStats_ODI_team_info_image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid white;
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
}

.statsNguts__seriesTeamStats_ODI_team_info_image:hover {
  transform: scale(1.1);
}

.statsNguts__seriesTeamStats_ODI_player_card {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.05)
  );
  padding: 18px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

.statsNguts__seriesTeamStats_ODI_player_card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(255, 255, 255, 0.2);
}

.statsNguts__seriesTeamStats_ODI_player_card h4 {
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-bottom: 5px;
}

.statsNguts__seriesTeamStats_ODI_player_card p {
  font-size: 14px;
  color: #ddd;
  margin: 3px 0;
}

.statsNguts__seriesTeamStats_ODI_player_card_img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffffff;
  margin-bottom: 10px;
}

.statsNguts__seriesTeamStats_ODI_player_status {
  font-size: 1rem;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 6px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
}

.status-available {
  color: #28a745;
  background: rgba(40, 167, 69, 0.2);
  border: 1px solid #28a745;
}

.status-unavailable {
  color: #dc3545;
  background: rgba(220, 53, 69, 0.2);
  border: 1px solid #dc3545;
}

.statsNguts__seriesTeamStats_ODI_player_section {
  margin-top: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(255, 255, 255, 0.1);
  text-align: center;
}

.statsNguts__seriesTeamStats_ODI_player_section h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffd700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.statsNguts__seriesTeamStats_ODI_player_grid {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1024px) {
  .statsNguts__seriesTeamStats_ODI_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .statsNguts__seriesTeamStats_ODI_team_details_container {
    grid-template-columns: repeat(4, 1fr);
  }

  .statsNguts__seriesTeamStats_ODI_team_header {
    font-size: 1.5rem;
  }

  .statsNguts__seriesTeamStats_ODI_section_heading {
    font-size: 1.5rem;
  }

  .statsNguts__seriesTeamStats_ODI_team_info_title {
    font-size: 0.8rem;
  }

  .statsNguts__seriesTeamStats_ODI_team_info_name,
  .statsNguts__seriesTeamStats_ODI_player_card h4 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__seriesTeamStats_ODI_grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .statsNguts__seriesTeamStats_ODI_team_details_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .statsNguts__seriesTeamStats_ODI_team_header {
    font-size: 1.2rem;
  }

  .statsNguts__seriesTeamStats_ODI_section_heading {
    font-size: 1.2rem;
  }

  .statsNguts__seriesTeamStats_ODI_team_info_title {
    font-size: 0.8rem;
  }

  .statsNguts__seriesTeamStats_ODI_team_info_name,
  .statsNguts__seriesTeamStats_ODI_player_card h4 {
    font-size: 0.9rem;
  }

  .statsNguts__seriesTeamStats_ODI {
    padding: 15px;
  }

  .statsNguts__seriesTeamStats_ODI_team_details_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .statsNguts__seriesTeamStats_ODI_player_grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .statsNguts__seriesTeamStats_ODI_player_card {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__seriesTeamStats_ODI {
    padding: 10px;
  }

  .statsNguts__seriesTeamStats_ODI_team_details_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .statsNguts__seriesTeamStats_ODI_player_card {
    width: 90%;
  }

  .statsNguts__seriesTeamStats_ODI_team_header {
    font-size: 0.8rem;
  }

  .statsNguts__seriesTeamStats_ODI_section_heading {
    font-size: 0.8rem;
  }

  .statsNguts__seriesTeamStats_ODI_team_info_title {
    font-size: 0.7rem;
  }

  .statsNguts__seriesTeamStats_ODI_team_info_name {
    font-size: 0.7rem;
  }

  .statsNguts__seriesTeamStats_ODI_player_card h4 {
    font-size: 0.85rem;
  }
}
