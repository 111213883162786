.statsNguts__fanatsyTeamPrediction {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-family);
    margin-top: 40px;
  }
  
.statsNguts__fanatsyTeamPrediction h2 {
    font-size: 44px;
    color: #004d99;
    margin-bottom: 20px;
    text-align: center;
}

.statsNguts__fanatsyTeamPrediction_Disclaimer {
  font-size: 12px;
  color: #F2F2EF;
  text-align: center;
  margin-top: 10px;
}
  
.statsNguts__fanatsyTeamPrediction_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    padding: 20px;
    width: 100%;
  }
  
  .statsNguts__fanatsyTeamPrediction_container_team {
    background-color: #cac7ba;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    flex-basis: calc(25% - 20px);
    margin-bottom: 20px;
    box-sizing: border-box;
    text-align: center;
  }
  
  .statsNguts__fanatsyTeamPrediction_container_team_name {
    background-color: #004d99;
    color: #fff;
    padding: 10px 0;
    font-size: 1.25rem;
    font-weight: bold;
    width: 100%;
  }
  
  .statsNguts__fanatsyTeamPrediction_container_team_section h3 {
    color: #333;
    font-size: 0.8rem;
    margin: 20px 0 10px;
    text-align: center;
  }
  
  .statsNguts__fanatsyTeamPrediction_container_players {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
  }
  
  .statsNguts__fanatsyTeamPrediction_container_player_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: calc(25% - 10px);
    max-width: calc(25% - 10px);
  }
  
  .statsNguts__fanatsyTeamPrediction_container_player_avatar {
    width: 60px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    margin-bottom: 5px;
  }
  
  .statsNguts__fanatsyTeamPrediction_container_player_info .statsNguts__fanatsyTeamPrediction_container_player_name {
    font-size: 14px;
    color: #007bff;
  }
  
  .statsNguts__fanatsyTeamPrediction_container_player_info .statsNguts__fanatsyTeamPrediction_container_player_role_captain {
    font-size: 0.75rem;
    font-weight: bold;
    color: #28a745;
    margin-top: 2px;
  }
  
  .statsNguts__fanatsyTeamPrediction_container_player_info .statsNguts__fanatsyTeamPrediction_container_player_role_vice-captain {
    font-size: 0.75rem;
    font-weight: bold;
    color: #28a745;
    margin-top: 2px;
  }
  
  .statsNguts__fanatsyTeamPrediction_button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  @media (max-width: 1024px) {
    .statsNguts__fanatsyTeamPrediction_container_team {
      flex-basis: calc(50% - 20px);
    }
  
    .fantasyTeams__questions_and_answers_table th,
    .fantasyTeams__questions_and_answers_table td {
      padding: 16px;
      font-size: 16px;
    }
  }
  
  @media (max-width: 768px) {
    .statsNguts__fanatsyTeamPrediction_container_team {
      flex-basis: 100%;
    }
  
    .statsNguts__fanatsyTeamPrediction_container {
      padding: 0px;
    }
  
    .statsNguts__fanatsyTeamPrediction_container_team_name {
      font-size: 1.0rem;
    }
  
    .statsNguts__fanatsyTeamPrediction_container_team_section h3 {
      color: #333;
      font-size: 0.8rem;
      margin: 10px 0 5px;
    }
    
    .statsNguts__fanatsyTeamPrediction_container_players {
      gap: 5px;
    }
    
    .statsNguts__fanatsyTeamPrediction_container_player_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: calc(25% - 5px);
      max-width: calc(25% - 5px);
    }
    
    .statsNguts__fanatsyTeamPrediction_container_player_avatar {
      width: 40px;
      height: 60px;
      margin-bottom: 2px;
    }
    
    .statsNguts__fanatsyTeamPrediction_container_player_info .statsNguts__fanatsyTeamPrediction_container_player_name {
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .statsNguts__fanatsyTeamPrediction_container_team {
      flex-basis: 100%;
    }
  
    .statsNguts__fanatsyTeamPrediction_container {
      padding: 0px;
    }
  
    .statsNguts__fanatsyTeamPrediction_container_team_name {
      font-size: 1.0rem;
    }
  
    .statsNguts__fanatsyTeamPrediction_container_team_section h3 {
      color: #333;
      font-size: 0.8rem;
      margin: 20px 0 10px;
    }
    
    .statsNguts__fanatsyTeamPrediction_container_players {
      gap: 5px;
    }
    
    .statsNguts__fanatsyTeamPrediction_container_player_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: calc(25% - 5px);
      max-width: calc(25% - 5px);
    }
    
    .statsNguts__fanatsyTeamPrediction_container_player_avatar {
      width: 40px;
      height: 60px;
      margin-bottom: 2px;
    }
    
    .statsNguts__fanatsyTeamPrediction_container_player_info .statsNguts__fanatsyTeamPrediction_container_player_name {
      font-size: 12px;
    }
  
  .statsNguts__fanatsyTeamPrediction h2 {
      font-size: 24px;
   }
  
  .statsNguts__fanatsyTeamPrediction_Disclaimer {
    font-size: 8px;
    text-align: left;
  }
}
  