.statsNguts__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
    padding: 2rem;
    text-align: center;
}

.statsNguts__footer_links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}

.statsNguts__footer_links div {
    width: calc(25% - 2rem);
    margin: 1rem;
}

.statsNguts__footer_links_logo {
    display: flex;
    flex-direction: column;
}

.statsNguts__footer_links_logo img {
    width: auto;
    max-width: 100px;
    margin-bottom: 1rem;
}

.statsNguts__footer_links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.statsNguts__footer_links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.statsNguts__footer_links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;
    margin-bottom: 0.9rem;
}

.statsNguts__footer_links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;
    margin: 0.5rem 0;
    cursor: pointer;
}

.statsNguts__footer_copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.statsNguts__footer_copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 1024px) {
    .statsNguts__footer_links div {
        width: calc(25% - 2rem);
    }
}

@media screen and (max-width: 768px) {
    .statsNguts__footer_links div {
        width: calc(50% - 2rem);
    }
}

@media screen and (max-width: 480px) {
    .statsNguts__footer_links div {
        width: 100%;
        margin: 1rem 0;
    }
}
