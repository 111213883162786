.statsNguts__seriesDescription_WPL2025 {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  padding: 2rem;
}

.statsNguts__seriesDescription_WPL2025_heading,
.statsNguts__seriesDescription_WPL2025_teams_heading {
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

.statsNguts__seriesDescription_WPL2025_heading h1,
.statsNguts__seriesDescription_WPL2025_teams_heading h1 {
  font-size: 56px;
  line-height: 75px;
  font-weight: 800;
}

.statsNguts__seriesDescription_WPL2025_text {
  font-size: 18px;
  line-height: 1.6;
  color: white;
  text-align: justify;
  margin-bottom: 2rem;
}

.statsNguts__seriesDescription_WPL2025_text p {
  margin-bottom: 1rem;
}

.statsNguts__seriesDescription_WPL2025_teams_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  justify-items: center;
  margin-top: 20px;
}

.statsNguts__seriesDescription_WPL2025_teams_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.05)
  );
  padding: 15px;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
  text-align: center;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.statsNguts__seriesDescription_WPL2025_teams_card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(255, 223, 0, 0.4);
}

.statsNguts__seriesDescription_WPL2025_teams_images {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-bottom: 5px;
}

.statsNguts__seriesDescription_WPL2025_team_logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 8px;
}

.statsNguts__seriesDescription_WPL2025_team_name {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffd700;
  background: linear-gradient(45deg, #3a3d40, #181818);
  padding: 6px;
  width: 90%;
  min-height: 35px;
  max-height: 35px;
  border-radius: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 2px 2px 6px rgba(255, 255, 255, 0.2);
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  line-height: 1.2;
  margin-top: 5px;
}

.statsNguts__seriesDescription_WPL2025_teams_card:hover
  .statsNguts__seriesDescription_WPL2025_team_name {
  transform: scale(1.05);
  color: #ffffff;
  background: linear-gradient(45deg, #181818, #3a3d40);
}

.statsNguts__seriesDescription_WPL2025_statsSection {
  margin: 2rem 0;
  text-align: center;
}

.statsNguts__seriesDescription_WPL2025_section_heading {
  font-size: 28px;
  font-weight: bold;
  color: #ffcc00;
  text-align: left;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid rgba(255, 204, 0, 0.5);
  padding-bottom: 0.5rem;
}

.statsNguts__seriesDescription_WPL2025_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.statsNguts__seriesDescription_WPL2025_card {
  background: linear-gradient(145deg, #1e1e2f, #2a2a3f);
  border-radius: 12px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.statsNguts__seriesDescription_WPL2025_card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 25px rgba(255, 223, 0, 0.5);
}

.statsNguts__seriesDescription_WPL2025_title {
  font-size: 20px;
  color: #ffd700;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.statsNguts__seriesDescription_WPL2025_details {
  margin-top: 1rem;
}

.statsNguts__seriesDescription_WPL2025_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
}

.statsNguts__seriesDescription_WPL2025_detail.highlight {
  background: rgba(255, 223, 0, 0.2);
  border: 2px solid #ffd700;
}

.statsNguts__seriesDescription_WPL2025_photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
}

.statsNguts__seriesDescription_WPL2025_player {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-left: 0.5rem;
}

.statsNguts__seriesDescription_WPL2025_value {
  font-size: 12px;
  color: #ddd;
}

@media screen and (max-width: 1024px) {
  .statsNguts__seriesDescription_WPL2025_heading h1 {
    font-size: 42px;
    line-height: 60px;
  }

  .statsNguts__seriesDescription_WPL2025_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .statsNguts__seriesDescription_WPL2025_teams_heading h1 {
    font-size: 42px;
    line-height: 60px;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__seriesDescription_WPL2025_heading h1 {
    font-size: 32px;
    line-height: 52px;
  }

  .statsNguts__seriesDescription_WPL2025_teams_heading h1 {
    font-size: 32px;
    line-height: 52px;
  }

  .statsNguts__seriesDescription_WPL2025_text {
    font-size: 16px;
  }

  .statsNguts__seriesDescription_WPL2025_teams_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .statsNguts__seriesDescription_WPL2025_teams_card {
    height: 180px;
    min-height: 180px;
    max-height: 180px;
  }

  .statsNguts__seriesDescription_WPL2025_teams_images {
    margin-bottom: 3px;
  }

  .statsNguts__seriesDescription_WPL2025_team_name {
    font-size: 14px;
    min-height: 30px;
    max-height: 30px;
  }

  .statsNguts__seriesDescription_WPL2025_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__seriesDescription_WPL2025_heading h1 {
    font-size: 24px;
    line-height: 42px;
  }

  .statsNguts__seriesDescription_WPL2025_teams_heading h1 {
    font-size: 24px;
    line-height: 42px;
  }

  .statsNguts__seriesDescription_WPL2025_section_heading {
    font-size: 20px;
  }

  .statsNguts__seriesDescription_WPL2025_text {
    font-size: 14px;
  }

  .statsNguts__seriesDescription_WPL2025_teams_grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .statsNguts__seriesDescription_WPL2025_teams_card {
    height: 170px;
    min-height: 170px;
    max-height: 170px;
  }

  .statsNguts__seriesDescription_WPL2025_teams_images {
    margin-bottom: 2px;
  }

  .statsNguts__seriesDescription_WPL2025_team_name {
    font-size: 14px;
    min-height: 30px;
  }
}
