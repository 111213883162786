.statsNguts__privacyPolicy {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 20px;
  font-family: var(--font-family);
}
  
.statsNguts__privacyPolicy_heading {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 20px;
}
  
.statsNguts__privacyPolicy_heading h1 {
  font-size: 60px;
  line-height: 75px;
  font-weight: 800;
  margin-bottom: 20px;
}

.statsNguts__privacyPolicy_heading h2 {
  font-size: 40px;
  color: #065777;
  margin-bottom: 10px;
}

.statsNguts__privacyPolicy_heading p {
  font-size: 20px;
  line-height: 1.5;
  color: #666;
}

.statsNguts__privacyPolicy_heading h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

@media screen and (max-width: 1024px) {
  .statsNguts__privacyPolicy_heading h1 {
    font-size: 60px;
  }

  .statsNguts__privacyPolicy_heading h2 {
    font-size: 40px;
  }
    
  .statsNguts__privacyPolicy_heading p {
    font-size: 20px;
  }

  .statsNguts__privacyPolicy_heading h3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__privacyPolicy_heading h1 {
    font-size: 48px;
  }

  .statsNguts__privacyPolicy_heading h2 {
    font-size: 30px;
  }
    
  .statsNguts__privacyPolicy_heading p {
    font-size: 16px;
  }

  .statsNguts__privacyPolicy_heading h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__privacyPolicy_heading h1 {
    font-size: 32px;
  }

  .statsNguts__privacyPolicy_heading h2 {
    font-size: 16px;
  }
    
  .statsNguts__privacyPolicy_heading p {
    font-size: 12px;
  }

  .statsNguts__privacyPolicy_heading h3 {
    font-size: 10px;
  }
}