.statsNguts__seriesDescription_ODI {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  gap: 1rem;
}

.statsNguts__seriesDescription_ODI_heading {
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;
}

.statsNguts__seriesDescription_ODI_heading h1 {
  font-size: 56px;
  line-height: 75px;
  font-weight: 800;
}

.statsNguts__seriesDescription_ODI_text {
  font-size: 18px;
  line-height: 1.6;
  color: white;
  text-align: justify;
  margin-bottom: 2rem;
}

.statsNguts__seriesDescription_ODI_text p {
  margin-bottom: 1rem;
}

.statsNguts__seriesDescription_ODI_series_result_player {
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
}

.statsNguts__seriesDescription_ODI_teams_heading {
  width: 100%;
  text-align: left;
  margin-bottom: 5rem;
}

.statsNguts__seriesDescription_ODI_teams_heading h1 {
  font-size: 56px;
  line-height: 75px;
  font-weight: 800;
}

.statsNguts__seriesDescription_ODI_teams_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  align-items: flex-start;
  margin-top: 20px;
}

.statsNguts__seriesDescription_ODI_teams_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.05)
  );
  padding: 15px;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
  text-align: center;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.statsNguts__seriesDescription_ODI_teams_card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(255, 223, 0, 0.4);
}

.statsNguts__seriesDescription_ODI_teams_images {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-bottom: 5px;
}

.statsNguts__seriesDescription_ODI_team_logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 8px;
}

.statsNguts__seriesDescription_ODI_team_name {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #ffd700;
  background: linear-gradient(45deg, #3a3d40, #181818);
  padding: 6px;
  width: 90%;
  min-height: 35px;
  max-height: 35px;
  border-radius: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-shadow: 2px 2px 6px rgba(255, 255, 255, 0.2);
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  line-height: 1.2;
  margin-top: 5px;
}

.statsNguts__seriesDescription_ODI_statsSection {
  margin-bottom: 2rem;
  padding: 1rem;
}

.statsNguts__seriesDescription_ODI_section_heading {
  font-size: 28px;
  font-weight: bold;
  color: #ffcc00;
  text-align: left;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid rgba(255, 204, 0, 0.5);
  padding-bottom: 0.5rem;
}

.statsNguts__seriesDescription_ODI_series_result_inside {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid #ffd700;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #ffd700;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2rem;
}

.statsNguts__seriesDescription_ODI_series_result_text {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.statsNguts__seriesDescription_ODI_stats {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
}

.statsNguts__seriesDescription_ODI_statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23%;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.statsNguts__seriesDescription_ODI_statItem h3 {
  font-size: 18px;
  margin-bottom: 0.5rem;
  color: #ffcc00;
}

.statsNguts__seriesDescription_ODI_statList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.statsNguts__seriesDescription_ODI_statDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.statsNguts__seriesDescription_ODI_statDetail.highlight {
  background-color: rgba(
    255,
    223,
    0,
    0.2
  );
  border: 2px solid #ffd700;
  padding: 1rem;
}

.statsNguts__seriesDescription_ODI_photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  object-fit: cover;
  border: 2px solid #fff;
}

.statsNguts__seriesDescription_ODI_player {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.statsNguts__seriesDescription_ODI_value {
  font-size: 14px;
  color: #ddd;
}

@media screen and (max-width: 1024px) {
  .statsNguts__seriesDescription_ODI_heading h1 {
    font-size: 36px;
    line-height: 52px;
  }

  .statsNguts__seriesDescription_ODI_teams_heading h1 {
    font-size: 36px;
    line-height: 52px;
  }

  .statsNguts__seriesDescription_ODI_statItem {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__seriesDescription_ODI_heading h1 {
    font-size: 32px;
    line-height: 52px;
  }

  .statsNguts__seriesDescription_ODI_teams_heading h1 {
    font-size: 32px;
    line-height: 52px;
  }

  .statsNguts__seriesDescription_ODI_text {
    font-size: 16px;
  }

  .statsNguts__seriesDescription_ODI_statItem {
    width: 100%;
  }

  .statsNguts__seriesDescription_ODI_teams_grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .statsNguts__seriesDescription_ODI_series_result_inside {
    font-size: 18px;
    padding: 1rem;
  }

  .statsNguts__seriesDescription_ODI_series_result_text {
    font-size: 20px;
  }

  .statsNguts__seriesDescription_ODI_series_result_player {
    font-size: 20px;
  }

  .statsNguts__seriesDescription_ODI_teams_images .statsNguts__seriesDescription_ODI_team_logo {
    width: 100%;
  }

  .statsNguts__seriesDescription_ODI_team_name {
    font-size: 14px;
    min-height: 30px;
    max-height: 30px;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__seriesDescription_ODI_heading h1 {
    font-size: 24px;
    line-height: 42px;
  }

  .statsNguts__seriesDescription_ODI_teams_heading h1 {
    font-size: 24px;
    line-height: 42px;
  }

  .statsNguts__seriesDescription_ODI_text {
    font-size: 14px;
  }

  .statsNguts__seriesDescription_ODI_section_heading {
    font-size: 20px;
  }

  .statsNguts__seriesDescription_ODI_teams_grid {
    grid-template-columns: repeat(
      1,
      1fr
    );
  }

  .statsNguts__seriesDescription_ODI_teams_card {
    padding: 15px;
  }

  .statsNguts__seriesDescription_ODI_teams_images {
    flex-direction: row;
    gap: 5px;
  }

  .statsNguts__seriesDescription_ODI_teams_images .statsNguts__seriesDescription_ODI_team_logo {
    width: 100%;
  }

  .statsNguts__seriesDescription_ODI_team_name {
    font-size: 14px;
    min-height: 30px;
  }

  .statsNguts__seriesDescription_ODI_series_result_text {
    font-size: 18px;
  }

  .statsNguts__seriesDescription_ODI_series_result_player {
    font-size: 18px;
  }
}
