.statsNguts__ground_trends {
  text-align: left;
  word-wrap: break-word;
  font-family: var(--font-family);
  margin: 10px;
  padding: 10px;
}

.statsNguts__ground_trends h1 {
  font-size: 14px;
  color: #666666;
  margin-bottom: 5px;
  margin-top: 5px;
}

.statsNguts__ground_trends_toggle {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  font-size: 20px;
  color: #ffffff;
  background-color: #5a81d4;
  padding: 1%;
}

.statsNguts__ground_trends_toggle:focus {
  outline: none;
}

.statsNguts__ground_chartContainer {
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  flex-wrap: wrap; 
}

.statsNguts__ground_chartContainer_chart {
  width: calc(50% - 20px); 
  margin-bottom: 20px; 
}

@media screen and (max-width: 1024px) {
  .statsNguts__ground_chartContainer {
    flex-direction: column; 
    align-items: stretch; 
  }

  .statsNguts__ground_chartContainer_chart {
    width: 100%;
    margin: 0 auto 20px; 
  }

  .statsNguts__ground_trends_toggle {
    font-size: 18px;
  }

  .statsNguts__ground_trends {
      flex-direction: column; 
      align-items: stretch; 
  }

  .statsNguts__ground_trends h1 {
    font-size: 18px;
  }

  .statsNguts__ground_trends h2 {
    font-size: 12px;
  }
}
  
@media screen and (max-width: 768px) {
  .statsNguts__ground_chartContainer {
    flex-direction: column; 
    align-items: stretch;
  }

  .statsNguts__ground_chartContainer_chart {
    width: 100%; 
    margin: 0 auto 20px;
  }

  .statsNguts__ground_trends_toggle {
    font-size: 18px;
  }

  .statsNguts__ground_trends {
      flex-direction: column; 
      align-items: stretch; 
  }

  .statsNguts__ground_trends h1 {
    font-size: 18px;
  }

  .statsNguts__ground_trends h2 {
    font-size: 12px;
  }
}
  
@media screen and (max-width: 480px) {
  .statsNguts__ground_chartContainer {
    flex-direction: column; 
    align-items: stretch; 
  }

  .statsNguts__ground_chartContainer_chart {
    width: 100%; 
    margin: 0 auto 20px; 
  }

  .statsNguts__ground_trends_toggle {
    font-size: 16px;
  }

  .statsNguts__ground_trends {
      flex-direction: column; 
      align-items: stretch; 
  }

  .statsNguts__ground_trends h1 {
    font-size: 16px;
  }

  .statsNguts__ground_trends h2 {
    font-size: 10px;
  }
}