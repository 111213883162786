/* General Styles */
.statsNguts__seriesTeamStats {
  text-align: center;
  margin-top: 40px;
  padding: 30px;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 32, 0.9),
    rgba(0, 0, 64, 0.95)
  ); /* Elegant dark blue gradient */
  border-radius: 12px;
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* 🏆 Team Name Styling - Improved */
.statsNguts__seriesTeamStats h2 {
  font-size: 42px;
  font-weight: bold;
  text-transform: uppercase;
  background: linear-gradient(
    to right,
    #3f3e3a,
    #d4a686
  ); /* Gold to orange gradient */
  display: inline-block;
  margin-bottom: 15px;
  text-align: center;
  letter-spacing: 1.5px;
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
}

/* 🏏 Captain & Team Logo Section - Always in One Row */
.statsNguts__seriesTeamStats_images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: nowrap; /* Always stays in a row */
}

/* Team Stats Section */
.statsNguts__seriesTeamStats_statsSection {
  margin: 2rem 0;
  text-align: center;
}

.statsNguts__seriesTeamStats_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  gap: 2rem; /* Space between cards */
}

.statsNguts__seriesTeamStats_card {
  background: linear-gradient(145deg, #1e1e2f, #2a2a3f);
  border-radius: 12px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.statsNguts__seriesTeamStats_card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 25px rgba(255, 223, 0, 0.5); /* Add a glow effect on hover */
}

.statsNguts__seriesTeamStats_icon img {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

.statsNguts__seriesTeamStats_title {
  font-size: 20px;
  color: #ffd700; /* Gold color for titles */
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.statsNguts__seriesTeamStats_details {
  margin-top: 1rem;
}

.statsNguts__seriesTeamStats_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
}

.statsNguts__seriesTeamStats_detail.highlight {
  background: rgba(
    255,
    223,
    0,
    0.2
  ); /* Highlighted background for top players */
  border: 2px solid #ffd700;
}

.statsNguts__seriesTeamStats_photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
}

.statsNguts__seriesTeamStats_player {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-left: 0.5rem;
}

.statsNguts__seriesTeamStats_value {
  font-size: 12px;
  color: #ddd;
}

/* Captain & Team Logo Styling */
.captain-img,
.team-logo {
  width: 120px;
  height: 120px;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 3px solid rgba(255, 255, 255, 0.5);
  padding: 8px;
  transition: transform 0.3s ease;
}

.captain-img:hover,
.team-logo:hover {
  transform: scale(1.1);
}

/* 🏏 Player Sections */
.player-section {
  margin-top: 30px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(255, 255, 255, 0.1);
  text-align: left; /* Left align section content */
}

/* 🏏 Section Titles (Batters, Bowlers, etc.) Left Aligned */
.player-section h3 {
  font-size: 24px;
  font-weight: bold;
  color: #00aeff; /* Gold for category titles */
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: left; /* Left align section titles */
}

/* 📋 Player Grid - UNCHANGED */
.player-grid {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(4, 1fr);
}

/* 📋 Player Card - UNCHANGED */
.player-card {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0.05)
  );
  padding: 18px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

.player-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(255, 255, 255, 0.2);
}

/* 📸 Player Image - UNCHANGED */
.player-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ffffff;
  margin-bottom: 10px;
}

/* Player Name - UNCHANGED */
.player-card h4 {
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-bottom: 5px;
}

/* Player Info - UNCHANGED */
.player-card p {
  font-size: 14px;
  color: #ddd;
  margin: 3px 0;
}

/* Responsive Adjustments */
@media screen and (max-width: 1024px) {
  .statsNguts__seriesTeamStats_grid {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row */
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__seriesTeamStats_grid {
    grid-template-columns: repeat(
      1,
      1fr
    ); /* Single column layout for smaller screens */
  }
}

/* 🏆 Responsive Adjustments */
@media screen and (max-width: 1200px) {
  .statsNguts__seriesTeamStats {
    padding: 20px;
  }

  /* 🏏 Captain & Logo Always in Row on Tablets */
  .statsNguts__seriesTeamStats_images {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
  }

  .captain-img,
  .team-logo {
    width: 100px;
    height: 100px;
  }

  /* 📋 Display 2 Cards Per Row on Tablet - UNCHANGED */
  .player-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .statsNguts__seriesTeamStats h2 {
    font-size: 38px;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__seriesTeamStats {
    padding: 15px;
  }

  /* 🏏 Captain & Logo Always in Row on Mobile */
  .statsNguts__seriesTeamStats_images {
    flex-direction: row;
    justify-content: center;
    gap: 15px;
  }

  .captain-img,
  .team-logo {
    width: 90px;
    height: 90px;
  }

  /* 📋 Display 1 Card Per Row on Mobile - UNCHANGED */
  .player-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .player-card {
    width: 80%;
    margin: 0 auto;
  }

  .statsNguts__seriesTeamStats h2 {
    font-size: 32px;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__seriesTeamStats {
    padding: 10px;
  }

  .captain-img,
  .team-logo {
    width: 80px;
    height: 80px;
  }

  .player-card {
    width: 90%;
  }

  .statsNguts__seriesTeamStats h2 {
    font-size: 28px;
  }
}
