.statsNguts__seriesDescription_T20I {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  gap: 1rem;
}

.statsNguts__seriesDescription_T20I_heading {
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;
}

.statsNguts__seriesDescription_T20I_heading h1 {
  font-size: 56px;
  line-height: 75px;
  font-weight: 800;
}

.statsNguts__seriesDescription_T20I_text {
  font-size: 18px;
  line-height: 1.6;
  color: white;
  text-align: justify;
  margin-bottom: 2rem;
}

.statsNguts__seriesDescription_T20I_text p {
  margin-bottom: 1rem;
}

.statsNguts__seriesDescription_T20I_teams_heading {
  width: 100%;
  text-align: left;
  margin-bottom: 5rem;
}

.statsNguts__seriesDescription_T20I_teams_heading h1 {
  font-size: 56px;
  line-height: 75px;
  font-weight: 800;
}

/* Stats Section */
.statsNguts__seriesDescription_T20I_stats {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
}

.statsNguts__seriesDescription_T20I_statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 23%;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.statsNguts__seriesDescription_T20I_statList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.statsNguts__seriesDescription_T20I_statDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.statsNguts__seriesDescription_T20I_statDetail.highlight {
  background-color: rgba(
    255,
    223,
    0,
    0.2
  ); /* Highlight background for the first player */
  border: 2px solid #ffd700; /* Gold border for the first player */
  padding: 1rem;
}

/* Individual Team Card */
.statsNguts__seriesDescription_T20I_teams_card {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content inside each card to the left */
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
}

.statsNguts__seriesDescription_T20I_teams_card:hover {
  box-shadow: 0px 12px 25px rgba(255, 223, 0, 0.5); /* Add glow effect */
    transform: translateY(-5px); /* Slight upward movement instead of scaling */
}

.statsNguts__seriesDescription_T20I_statItem h3 {
  font-size: 18px;
  margin-bottom: 0.5rem;
  color: #ffcc00;
}

.statsNguts__seriesDescription_T20I_photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  object-fit: cover;
  border: 2px solid #fff;
}

.statsNguts__seriesDescription_T20I_player {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.statsNguts__seriesDescription_T20I_value {
  font-size: 14px;
  color: #ddd;
}

/* Enhanced styles for the series result section */
.statsNguts__seriesResult {
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Semi-transparent background for effect */
  border: 2px solid #ffd700; /* Gold border for visibility */
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-align: center;
  color: #ffd700; /* Gold text color for a fancy look */
  font-size: 22px;
  font-weight: bold;
  margin-top: 2rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

/* Teams Section */
.statsNguts__seriesDescription_T20I_teams {
  text-align: left; /* Align the Teams section to the left */
  margin-top: 40px;
  margin-left: 2rem; /* Add spacing from the left */
}

.statsNguts__seriesDescription_T20I_teams h1 {
  font-size: 56px;
  line-height: 75px;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 20px;
}

/* Grid Layout for Teams */
.statsNguts__seriesDescription_T20I_teams_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  align-items: flex-start; /* Align grid items to the top */
  margin-top: 20px;
}

.statsNguts__seriesResult_text {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.statsNguts__seriesResult_player {
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
}

.statsNguts__seriesDescription_T20I_statsSection {
  margin-bottom: 2rem;
}

.section-heading {
  font-size: 28px;
  font-weight: bold;
  color: #ffcc00; /* Gold color for the headings */
  text-align: left;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid rgba(255, 204, 0, 0.5); /* Decorative underline */
  padding-bottom: 0.5rem;
}

/* Images Container */
.statsNguts__seriesDescription_T20I_teams_images {
  display: flex;
  justify-content: space-between; /* Ensure images take 50% each */
  align-items: center;
  width: 100%; /* Use full width of the container */
  gap: 0; /* Remove extra spacing */
}

/* Captain Image */
.statsNguts__seriesDescription_T20I_teams_images .captain-img {
  width: 50%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    transition: none !important; /* Remove any hover effect */
    transform: none !important; /* Prevent movement */
    background-color: #000;
}

/* Team Logo */
.statsNguts__seriesDescription_T20I_teams_images .team-logo {
  width: 50%;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    transition: none !important; /* Remove any hover effect */
    transform: none !important; /* Prevent movement */
    background-color: #000;
}

/* Series Description Section */
.statsNguts__seriesDescription_T20I_statsSection {
  margin-bottom: 2rem;
  padding: 1rem;
}

/* Series Result Inside Series Description */
.statsNguts__seriesResult_inside {
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  border: 2px solid #ffd700; /* Gold border for visibility */
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-align: center;
  color: #ffd700; /* Gold text color */
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2rem; /* Spacing before stats */
}

/* Text inside the series result */
.statsNguts__seriesResult_text {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .statsNguts__seriesResult_inside {
    font-size: 18px;
    padding: 1rem;
  }

  .statsNguts__seriesResult_text {
    font-size: 20px;
  }

  .statsNguts__seriesDescription_T20I_teams_images .captain-img,
  .statsNguts__seriesDescription_T20I_teams_images .team-logo {
    width: 50%; /* Keep images side-by-side in mobile view */
  }
}

/* Responsive Adjustments */
@media screen and (max-width: 1200px) {
  .statsNguts__seriesDescription_T20I_teams h1 {
    font-size: 42px;
  }
}

@media screen and (max-width: 1024px) {
  .statsNguts__seriesDescription_T20I_heading h1 {
    font-size: 36px;
    line-height: 52px;
  }

  .statsNguts__seriesDescription_T20I_statItem {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__seriesDescription_T20I_heading h1 {
    font-size: 32px;
    line-height: 52px;
  }

  .statsNguts__seriesDescription_T20I_text {
    font-size: 16px;
  }

  .statsNguts__seriesDescription_T20I_statItem {
    width: 100%;
  }

  .statsNguts__seriesResult {
    font-size: 20px;
    padding: 1rem;
  }

  .statsNguts__seriesDescription_T20I_teams_grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__seriesDescription_T20I_heading h1 {
    font-size: 24px;
    line-height: 42px;
  }

  .statsNguts__seriesDescription_T20I_text {
    font-size: 14px;
  }

  .statsNguts__seriesResult {
    font-size: 18px;
    padding: 0.8rem;
  }

  .section-heading {
    font-size: 20px;
  }

  .statsNguts__seriesDescription_T20I_teams_grid {
    grid-template-columns: repeat(
      1,
      1fr
    ); /* Single-column layout for small screens */
  }

  .statsNguts__seriesDescription_T20I_teams_card {
    padding: 15px;
  }

  .statsNguts__seriesDescription_T20I_teams_images {
    flex-direction: row; /* Ensure images remain side by side */
    gap: 5px;
  }

  .statsNguts__seriesDescription_T20I_teams_images .captain-img,
  .statsNguts__seriesDescription_T20I_teams_images .team-logo {
    width: 50%; /* Each image takes 50% of the container */
  }
}
