.statsNguts__playerStats {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 20px;
    font-family: var(--font-family);
  }

.statsNguts__playerStats_heading {
    width: 100%;
    text-align: left;
    margin-bottom: 0px;
    margin-top: 20px;
}

.statsNguts__playerStats_heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-weight: 800;
}

.statsNguts__playerStats_heading h3 {
  font-size: 20.11px;
  font-weight: 800;
  line-height: 30.30px;
  color: #fff;
  margin-bottom: 5rem;
  font-style: italic
}

.statsNguts__playerStats_teamSection {
  width: 100%;
  box-sizing: border-box;
  display: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #383b42;
  border: 2px solid #0d0d0e;
}

.statsNguts__playerStats_teamSection.active {
  display: block;
}


.statsNguts__playerStats_teamSection h2 {
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  background-color: #d6d5e2;
  border: 2px solid #ddd;
  padding: 15px;
  margin: 0;
  font-size: 18px;
  color: #333333;
  font-weight: 600;
  font-family: var(--font-family);
}
  
.statsNguts__playerStats_teamSection_playersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.statsNguts__playerStats_teamSection_playersContainer > * {
  margin-bottom: 20px;
}

.statsNguts__playerStats_teamSection_playersContainer > *:nth-child(2n) {
  margin-right: 0;
}

.statsNguts__playerStats_tabs {
    display: flex;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  
.statsNguts__playerStats_tabButton {
    flex: 1;
    padding: 15px;
    cursor: pointer;
    background-color: #f4f4f4;
    border: 2px solid #ddd;
    outline: none;
    transition: background-color 0.3s ease;
    background: linear-gradient(to right, #4caf50, #2196f3); 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); 
    padding: 20px; 
    font-size: 18px;
    font-family: var(--font-family);
}
  
.statsNguts__playerStats_tabButton.active {
    background-color: #4caf50;
    color: #fff;
}

.statsNguts__playerStats_tabButton:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

@media screen and (max-width: 1024px) {
  .statsNguts__playerStats_heading h1 {
    font-size: 46px;
    line-height: 52px;
  }

  .statsNguts__playerStats_heading h3 {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__playerStats_heading h1 {
    font-size: 46px;
    line-height: 52px;
  }

  .statsNguts__playerStats_heading h3 {
    font-size: 12px;
    line-height: 22px;
  }
}

@media screen and (max-width: 480px) {
  .statsNguts__playerStats_heading h1 {
    font-size: 24px;
    line-height: 42px;
  }

  .statsNguts__playerStats_heading h3 {
    font-size: 10px;
    line-height: 22px;
  }
}


  
  