.statsNguts__fantasyTeamBuilder {
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

/* 🏏 Player Card */
.statsNguts__fantasyTeamBuilder_player_card {
  max-width: 450px; /* Limits the width for larger screens */
  width: 100%;
  background: #353e43;
  border-radius: 12px;
  border: 3px solid #004d99;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 20px auto;
  transition: border 0.3s ease-in-out, transform 0.2s ease-in-out;
}

/* 📌 Top Section: Image on Left, Details on Right */
.statsNguts__fantasyTeamBuilder_player_top {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  background: #f8f9fa;
  border-bottom: 2px solid #004d99;
}

/* 📷 Player Image (Left) */
.statsNguts__fantasyTeamBuilder_player_image_container {
  flex: 0 0 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statsNguts__fantasyTeamBuilder_player_image {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  object-fit: cover;
}

/* 📋 Player Details (Right) */
.statsNguts__fantasyTeamBuilder_player_details {
  flex: 1;
  padding-left: 12px;
  display: flex;
  flex-direction: row; /* Keep name and details on the same row */
  justify-content: flex-start; /* Align items to the left */
  align-items: center; /* Vertically align name with role and team */
}

/* Player Name */
.player_name {
  font-size: 18px;
  font-weight: bold;
  color: #222;
  white-space: normal; /* Allows natural wrapping */
  overflow: visible;
  text-overflow: unset;
}

/* Player Role and Team Container */
.statsNguts__fantasyTeamBuilder_role_team {
  display: flex;
  flex-direction: column; /* Stack role and team vertically */
  margin-left: 10px; /* Space between name and role/team */
  white-space: nowrap;
  align-items: center;
}

/* Player Role */
.player_role {
  font-size: 14px;
  background: #007bff;
  color: white;
  padding: 4px 8px;
  border-radius: 10px;
  margin-bottom: 5px; /* Space between role and team */
}

/* Player Team */
.player_team {
  font-size: 14px;
  background: #0a360a;
  color: white;
  padding: 4px 8px;
  border-radius: 10px;
}

/* 📊 Player Stats */
.statsNguts__fantasyTeamBuilder_player_stats_container {
  padding: 12px;
}

.statsNguts__fantasyTeamBuilder_stats_heading {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #30465c;
  padding: 8px;
  border-radius: 5px;
}

/* 🌟 Stats Grid - Ensure Stats Title & Value are in One Row */
.statsNguts__fantasyTeamBuilder_stats_grid {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 6px;
}

/* 📌 Stats Card */
.statsNguts__fantasyTeamBuilder_stat_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 16px; /* Increased font size */
  background: #f1f1f1;
  border-radius: 8px;
}

.statsNguts__fantasyTeamBuilder_stat_card h5 {
  font-size: 14px;
  text-align: left;
  flex: 1;
  white-space: nowrap; /* Prevents breaking */
}

.statsNguts__fantasyTeamBuilder_stat_card p {
  font-size: 14px;
  padding: 5px 7px;
  text-align: right;
  white-space: normal; /* Allow the text to wrap */
  word-wrap: break-word; /* Break long words if necessary */
}

/* 🎮 Buttons: Ensure Add & Skip are in the Same Row */
.statsNguts__fantasyTeamBuilder_button_container {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background: #f8f9fa;
  gap: 8px;
}

.btn {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.btn svg {
  margin-right: 5px;
}

/* ✅ Add Button */
.btn-add {
  background: #28a745;
  color: white;
}

.btn-add:hover {
  background: #218838;
}

/* 🚫 Disabled Button */
.btn-disabled {
  background: #c4c4c4;
  color: #888;
  cursor: not-allowed;
}

/* ⏭ Skip Button */
.btn-skip {
  background: #dc3545;
  color: white;
}

.btn-skip:hover {
  background: #c82333;
}

/* 🏆 Final Message */
.statsNguts__fantasyTeamBuilder_final_message_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.statsNguts__fantasyTeamBuilder_final_message {
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.btn-reset {
  background-color: #ff5733;
  color: white;
  border: none;
  padding: 10px 16px;
  font-size: 1rem; /* Default font size */
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  transition: background 0.3s ease-in-out;
  width: 100%;
  max-width: 250px; /* Prevents button from being too wide */
}

.btn-reset:hover {
  background-color: #e14e2a;
}

/* 📌 Fantasy Team Selection Summary */
.statsNguts__fantasyTeamBuilder_summary {
  background: #222f3e;
  color: white;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* 🎯 Title */
.summary_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* 🔢 Progress Bar */
.summary_progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 10px;
}

.summary_progress progress {
  flex: 1;
  height: 10px;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  background: #555;
}

.summary_progress progress::-webkit-progress-bar {
  background-color: #555;
  border-radius: 5px;
}

.summary_progress progress::-webkit-progress-value {
  background: #28a745;
  border-radius: 5px;
}

/* 📊 Role Breakdown */
.summary_roles {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.role_count {
  display: flex;
  align-items: center;
  background: #1e272e;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  width: 48%;
  justify-content: center;
}

.role_icon {
  font-size: 18px;
  margin-right: 8px;
}

@media (max-width: 1024px) {
  .statsNguts__fantasyTeamBuilder_player_card {
    max-width: 400px; /* Slightly smaller for tablets */
  }
}

/* 🌍 RESPONSIVE DESIGN */
@media (max-width: 768px) {
  .statsNguts__fantasyTeamBuilder_player_card {
    max-width: 90%;
  }

  .statsNguts__fantasyTeamBuilder_player_top {
    padding: 8px;
  }

  .statsNguts__fantasyTeamBuilder_player_image {
    width: 55px;
    height: 55px;
  }

  .player_name {
    font-size: 16px;
  }

  .player_role,
  .player_team,
  .player_country {
    font-size: 12px;
  }

  .statsNguts__fantasyTeamBuilder_stats_heading {
    font-size: 14px;
    padding: 6px;
  }

  .statsNguts__fantasyTeamBuilder_stat_card {
    padding: 6px;
  }

  .statsNguts__fantasyTeamBuilder_stat_card h5,
  .statsNguts__fantasyTeamBuilder_stat_card p {
    font-size: 11px;
  }

  /* Ensure buttons fit well */
  .btn {
    font-size: 12px;
    padding: 8px;
  }

  .btn-reset {
    font-size: 0.9rem; /* Slightly smaller font on tablets */
    padding: 8px 12px; /* Reduce padding */
    max-width: 220px;
  }
}

@media (max-width: 480px) {
  .statsNguts__fantasyTeamBuilder_player_card {
    width: 95%;
    max-width: 320px;
  }

  .statsNguts__fantasyTeamBuilder_player_top {
    padding: 6px;
  }

  .statsNguts__fantasyTeamBuilder_player_image {
    width: 50px;
    height: 50px;
  }

  .player_name {
    font-size: 14px;
  }

  .statsNguts__fantasyTeamBuilder_stat_card {
    padding: 4px;
  }

  .statsNguts__fantasyTeamBuilder_stat_card h5 {
    font-size: 9px;
  }

  .statsNguts__fantasyTeamBuilder_stat_card p {
    font-size: 10px;
  }

  /* Adjust button spacing */
  .btn {
    font-size: 11px;
    padding: 7px;
  }

  .statsNguts__fantasyTeamBuilder_button_container {
    flex-direction: row;
    gap: 5px;
  }

  .btn-reset {
    font-size: 0.85rem; /* Reduce font size for mobile */
    padding: 7px 10px; /* Further reduce padding */
    max-width: 200px;
  }

  /* Player Role */
  .player_role {
    font-size: 12px;
  }

  /* Player Team */
  .player_team {
    font-size: 12px;
  }
}

/* 🔄 Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
