* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
  background:-moz-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);

  /* safari 5.1+,chrome 10+ */
  background:-webkit-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);

  /* opera 11.10+ */
  background:-o-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);

  /* ie 10+ */
  background:-ms-radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 1rem 2rem;
}

.section__margin {
  margin: 2rem 3rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--color-bg);
}

.loading-container p {
  margin: 0;
  font-size: 24px;
  color: #13743b;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.05em; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); opacity: 0.5; } 
  100% { transform: rotate(360deg); opacity: 1; } 
}

.spinner {
  border: 4px solid rgba(0,0,0,.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.5); /* Adding a glow effect */
}

/* Optional: Adding a second layer to the spinner for a more complex look */
.spinner::after {
  content: "";
  display: block;
  width: 60px;
  height: 60px;
  margin: -5px 0 0 -5px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid rgba(52, 152, 219, 0.25);
  animation: spin 3s linear infinite reverse;
}

.error-message {
  color: #ff1744;
  text-align: center;
  font-size: 1.5rem;
  margin: 20px auto;
  max-width: 600px;
  padding: 20px;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 1024px) {
  .error-message {
      font-size: 2rem;
      max-width: 800px;
  }

  .loading-container p {
    font-size: 22px;
  }
  
  .spinner {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 768px) {
  .section__padding {
    padding: 1rem 1rem;
  }

  .section__margin {
    margin: 2rem;
  }

  .error-message {
    font-size: 1.2rem;
    padding: 15px;
  }

  .loading-container p {
    font-size: 20px;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .section__padding {
    padding: 0.5rem 0.5rem;
  }

  .section__margin {
    margin: 2rem 1rem;
  }

  .error-message {
    font-size: 1rem;
    padding: 10px;
    margin: 20px 10px;
  }

  .loading-container {
      height: auto;
      padding: 20px;
  }

  .loading-container p {
    font-size: 18px;
  }
  
  .spinner {
    width: 35px;
    height: 35px;
  }
}