/* 🎴 Flashy Fantasy Card Styling */
.statsNguts__fantasyCard {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  max-width: 360px;
  min-height: 580px; /* ✅ Adjusts dynamically */
  height: auto; /* ✅ Expands if needed */
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: linear-gradient(135deg, #001f3f, #002b5c, #004080);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid rgba(0, 170, 255, 0.5);
}

/* 🔥 Neon Glow Effect */
@keyframes glowPulse {
  0% {
    box-shadow: 0 0 10px rgba(0, 170, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 20px rgba(0, 170, 255, 0.8);
  }
}

.statsNguts__fantasyCard:hover {
  transform: scale(1.02);
  box-shadow: 0 18px 40px rgba(0, 170, 255, 0.8);
  border-color: rgba(0, 255, 255, 0.8);
}

/* 🖼 Player Image */
.statsNguts__fantasyCard_cardImage {
  width: 100%;
  height: 210px; /* ✅ Slightly reduced to balance name */
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
}

.statsNguts__fantasyCard_cardImage img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* ✅ Ensures full image is visible without cropping */
  border-bottom: 3px solid rgba(0, 255, 255, 0.5);
}

/* 📛 Player Name Box (Deep Blue Gradient) */
.statsNguts__fantasyCard_playerNameBox {
  width: 100%;
  background: linear-gradient(135deg, #003366, #004488);
  padding: 8px;
  text-align: center;
  min-height: 45px; /* ✅ Keeps height same for all names */
  display: flex;
  align-items: center;
  justify-content: center;
}

.statsNguts__fantasyCard_playerName {
  font-size: 1.5rem; /* ✅ Reduced slightly to prevent excessive wrapping */
  font-weight: 700;
  color: #00ccff;
  white-space: normal; /* ✅ Allows name to wrap but not too much */
  word-wrap: break-word;
  text-align: center;
}

/* 🌎 Country Box (Dark Grey Gradient for Contrast) */
.statsNguts__fantasyCard_playerTeamBox {
  width: 100%;
  background: linear-gradient(135deg, #222, #444);
  padding: 6px;
  text-align: center;
}

.statsNguts__fantasyCard_playerTeam {
  font-size: 1rem; /* ✅ Reduced slightly to maintain balance */
  font-weight: 600;
  color: #00e6e6;
  text-transform: uppercase;
}

/* 📄 Content Section */
.statsNguts__fantasyCard_cardContent {
  width: 100%;
  padding: 15px;
  text-align: left;
  color: #fff;
  background: linear-gradient(145deg, #000, #001f3f);
  border-radius: 0 0 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1; /* ✅ Ensures content grows dynamically */
}

/* 📊 Player Stats */
.statsNguts__fantasyCard_playerStats {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  font-size: 0.95rem; /* ✅ Slightly reduced to keep things even */
  line-height: 1.5;
  color: #e6f7ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  word-wrap: break-word;
}

/* ✅ Key-Value Styling */
.statsNguts__fantasyCard_playerStats li {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column; /* ✅ Places key on a separate line from value */
  text-align: left;
}

/* ✅ Key Styling */
.statsNguts__fantasyCard_playerStats li strong {
  color: #00ffff;
  font-weight: 700;
  text-align: left;
  display: block;
  margin-bottom: 3px; /* ✅ Adds spacing between key and value */
}

/* ✅ Value Styling */
.statsNguts__fantasyCard_playerStats li span {
  font-size: 0.95rem;
  color: #e6f7ff;
}

/* 🌐 Responsive Fixes */
@media (max-width: 1048px) {
  .statsNguts__fantasyCard {
    max-width: 320px;
    min-height: 570px;
  }
}

@media (max-width: 768px) {
  .statsNguts__fantasyCard {
    max-width: 100%;
    min-height: auto; /* ✅ Adjusts dynamically */
  }
}

@media (max-width: 480px) {
  .statsNguts__fantasyCard {
    width: 100%;
    min-height: auto;
  }
}
