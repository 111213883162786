.statsNguts__player_chartContainer {
    text-align: left;
    word-wrap: break-word;
    font-family: var(--font-family);
    margin: 10px;
    padding: 10px;
    display: flex; 
    flex-wrap: wrap; 
}

.statsNguts__player_chartContainer h1 {
    font-size: 20px;
    color: #ffffff; 
    background-color: #9ab6f3; 
    text-align: center; 
    width: 100%; 
    margin-bottom: 10px;
    padding: 1%;
}
  
.statsNguts__player_chartContainer_chart {
    width: 100%; 
    margin-bottom: 20px; 
}

.chartToggle {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: #ffffff;
    background-color: #5a81d4;
    padding: 1%;
}
  
.chartToggle:focus {
    outline: none;
}

@media screen and (max-width: 1024px) {
    .chartToggle {
        font-size: 18px;
    }

    .statsNguts__player_chartContainer {
        flex-direction: column; 
        align-items: stretch; 
    }
    
    .statsNguts__player_chartContainer_chart {
        width: 100%; 
        margin: 0 0 20px; 
    }
    
    .statsNguts__player_chartContainer h1 {
        font-size: 18px;
    }
}
    
@media screen and (max-width: 768px) {
    .chartToggle {
        font-size: 18px;
    }

    .statsNguts__player_chartContainer {
        flex-direction: column; 
        align-items: stretch; 
    }
    
    .statsNguts__player_chartContainer_chart {
        width: 100%;
        margin: 0 0 20px;
    }
    
    .statsNguts__player_chartContainer h1 {
        font-size: 18px;
    }
}
    
@media screen and (max-width: 480px) {
    .chartToggle {
        font-size: 16px;
    }

    .statsNguts__player_chartContainer {
        flex-direction: column; 
        align-items: stretch; 
    }
    
    .statsNguts__player_chartContainer_chart {
        width: 100%;
        margin: 0 0 20px; 
    }
    
    .statsNguts__player_chartContainer h1 {
        font-size: 16px;
    }
}