.statsNguts__common_seriesSummary_card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: var(--color-footer);
    font-family: var(--font-family);
}

.statsNguts__common_seriesSummary_card_avatarContainer {
    width: 100%;
    flex-grow: 1;
    background: var(--color-bg);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
}

.statsNguts__common_seriesSummary_card_avatarContainer_avatar {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.statsNguts__common_seriesSummary_card_content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 40%;
}

.statsNguts__common_seriesSummary_card_content p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #f5f5f5;
    align-self: flex-start;
}
  
.statsNguts__common_seriesSummary_card_content h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #009688;
    align-self: center;
}
  
.statsNguts__common_seriesSummary_card_content h3 {
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    align-self: flex-end;
}

.statsNguts__common_seriesSummary_card_content h4 {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: #48658b;
    align-self: flex-start;
}

@media screen and (max-width: 1024px) {
    .statsNguts__common_seriesSummary_card_content p {
        font-size: 14px;
    }
      
    .statsNguts__common_seriesSummary_card_content h2 {
        font-size: 14px;
    }
      
    .statsNguts__common_seriesSummary_card_content h3 {
        font-size: 14px;
    }
    
    .statsNguts__common_seriesSummary_card_content h4 {
        font-size: 10px;
    }
}

@media screen and (max-width: 768px) {
    .statsNguts__common_seriesSummary_card_content p {
        font-size: 20px;
    }
      
    .statsNguts__common_seriesSummary_card_content h2 {
        font-size: 16px;
    }
      
    .statsNguts__common_seriesSummary_card_content h3 {
        font-size: 16px;
    }
    
    .statsNguts__common_seriesSummary_card_content h4 {
        font-size: 16px;
    }
}

@media screen and (max-width: 480px) {
    .statsNguts__common_seriesSummary_card_content p {
        font-size: 16px;
    }
      
    .statsNguts__common_seriesSummary_card_content h2 {
        font-size: 12px;
    }
      
    .statsNguts__common_seriesSummary_card_content h3 {
        font-size: 12px;
    }
    
    .statsNguts__common_seriesSummary_card_content h4 {
        font-size: 10px;
    }
}