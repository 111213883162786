.statsNguts__feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  font-family: var(--font-family);
}

.statsNguts__feedback_heading {
  width: 100%;
  margin-bottom: 3rem;
  text-align: center;
}

.statsNguts__feedback_heading h1 {
  font-weight: 800;
  font-size: 56px;
  line-height: 75px;
}

.statsNguts__feedback_heading p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  margin-top: 1.5rem;
}

.statsNguts__feedback_icons a {
  margin: 0 10px;
}

.statsNguts__feedback_icons img {
  width: 50px; 
  height: 50px; 
  transition: transform 0.2s; 
}

.statsNguts__feedback_icons a:hover img {
  transform: scale(1.1);
}

@media screen and (max-width: 1024px) {
  .statsNguts__feedback_heading h1 {
    font-size: 56px;
  }
}

@media screen and (max-width: 768px) {
  .statsNguts__feedback_heading h1 {
    font-size: 56px;
  }  
}

@media screen and (max-width: 480px) {
  .statsNguts__feedback_heading h1 {
      font-size: 24px;
      line-height: 42px;
  }

  .statsNguts__feedback_btn p {
      font-size: 12px;
      line-height: 20px;
  }
}