.statsNguts__seriesDescription_T20I {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    gap: 1rem;
}

.statsNguts__seriesDescription_T20I_heading {
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
}

.statsNguts__seriesDescription_T20I_heading h1 {
    font-size: 56px;
    line-height: 75px;
    font-weight: 800;
}

.statsNguts__seriesDescription_T20I_text {
    font-size: 18px;
    line-height: 1.6;
    color: white;
    text-align: justify;
    margin-bottom: 2rem;
}

.statsNguts__seriesDescription_T20I_text p {
    margin-bottom: 1rem;
}

/* Stats Section */
.statsNguts__seriesDescription_T20I_stats {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
}

.statsNguts__seriesDescription_T20I_statItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23%;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.statsNguts__seriesDescription_T20I_statList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.statsNguts__seriesDescription_T20I_statDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.statsNguts__seriesDescription_T20I_statDetail.highlight {
    background-color: rgba(255, 223, 0, 0.2); /* Highlight background for the first player */
    border: 2px solid #ffd700; /* Gold border for the first player */
    padding: 1rem;
}

.statsNguts__seriesDescription_T20I_statItem h3 {
    font-size: 18px;
    margin-bottom: 0.5rem;
    color: #ffcc00;
}

.statsNguts__seriesDescription_T20I_photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 0.5rem;
    object-fit: cover;
    border: 2px solid #fff;
}

.statsNguts__seriesDescription_T20I_player {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

.statsNguts__seriesDescription_T20I_value {
    font-size: 14px;
    color: #ddd;
}

/* Enhanced styles for the series result section */
.statsNguts__seriesResult {
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background for effect */
    border: 2px solid #ffd700; /* Gold border for visibility */
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    text-align: center;
    color: #ffd700; /* Gold text color for a fancy look */
    font-size: 22px;
    font-weight: bold;
    margin-top: 2rem;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.statsNguts__seriesResult_text {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.statsNguts__seriesResult_player {
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
}

@media screen and (max-width: 1024px) {
    .statsNguts__seriesDescription_T20I_heading h1 {
        font-size: 36px;
        line-height: 52px;
    }

    .statsNguts__seriesDescription_T20I_statItem {
        width: 45%;
    }
}

@media screen and (max-width: 768px) {
    .statsNguts__seriesDescription_T20I_heading h1 {
        font-size: 32px;
        line-height: 52px;
    }

    .statsNguts__seriesDescription_T20I_text {
        font-size: 16px;
    }

    .statsNguts__seriesDescription_T20I_statItem {
        width: 100%;
    }

    .statsNguts__seriesResult {
        font-size: 20px;
        padding: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .statsNguts__seriesDescription_T20I_heading h1 {
        font-size: 24px;
        line-height: 42px;
    }

    .statsNguts__seriesDescription_T20I_text {
        font-size: 14px;
    }

    .statsNguts__seriesResult {
        font-size: 18px;
        padding: 0.8rem;
    }
}
