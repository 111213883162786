.statsNguts__groundStats {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 20px;
    font-family: var(--font-family);
  }

.statsNguts__groundStats_heading {
    width: 100%;
    text-align: left;
    margin-bottom: 0px;
    margin-top: 20px;
}

.statsNguts__groundStats_heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-weight: 800;
}

.statsNguts__groundStats_heading h3 {
    font-size: 20.11px;
    font-weight: 800;
    line-height: 30.30px;
    color: #fff;
    margin-bottom: 5rem;
    font-style: italic
  }

  @media screen and (max-width: 1024px) {
    .statsNguts__groundStats_heading h1 {
      font-size: 46px;
      line-height: 52px;
    }
  
    .statsNguts__groundStats_heading h3 {
      font-size: 12px;
      line-height: 22px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .statsNguts__groundStats_heading h1 {
      font-size: 46px;
      line-height: 52px;
    }
  
    .statsNguts__groundStats_heading h3 {
      font-size: 12px;
      line-height: 22px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .statsNguts__groundStats_heading h1 {
      font-size: 24px;
      line-height: 42px;
    }
  
    .statsNguts__groundStats_heading h3 {
      font-size: 10px;
      line-height: 22px;
    }
  }